import React, { useEffect, useState } from "react";
import { fetchAllNotifications, notificationsRead } from "../http/http-calls";
import { showToast } from "../helper-methods";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../redux/actions/user-credential";

const Notification = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const [notifications, setNotiofications] = useState([]);

  const _fetchNotifications = async () => {
    try {
      const res = await fetchAllNotifications();
      console.log("res>>", res);
      setNotiofications(res?.notifications);
    } catch (error) {
      console.log("error", error);
      showToast(error?.reason, "error");
    }
  };

  const _readNotification = async () => {
    try {
      const res = await notificationsRead();
      console.log("res>>", res);
      dispatch(
        updateUserData({ user: userCredential?.user, notificationCount: 0 })
      );
    } catch (error) {
      console.log("error", error);
      showToast(error?.reason, "error");
    }
  };

  useEffect(() => {
    _fetchNotifications();
  }, []);

  useEffect(() => {
    if (notifications?.length) {
      _readNotification();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);
  return (
    <>
      <div className="InnerWrapper">
        <div className="PgTitle">
          <h2 className="pg-heading">Notification</h2>
        </div>

        <div className="notificationList">
          <ul>
            {notifications?.length ? (
              notifications?.map((notification, index) => (
                <li
                  className={!notification?.isRead ? "newMessage" : ""}
                  key={index}
                  onClick={() =>  history.push("/provide-list")}
                >
                  <div className="nofiticationHeader">
                    <h2>{notification?.title}</h2>
                    <span>
                      {moment(notification?.createdAt)?.format(
                        "MMM Do YYYY h:mm a"
                      )}
                    </span>
                  </div>

                  <p>{notification?.body}</p>
                </li>
              ))
            ) : (
              <div className="noData">
                <img src={require("../assets/img/noData.svg")} alt="no data" />
                <p>There is no data to display</p>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Notification;
