import React, { Component } from "react";
import { Input, Form, Button, Label, FormGroup } from "reactstrap";
import { fotgotPassword } from "../http/http-calls";
import { showToast } from "../helper-methods";
import config from "../config";

class ForgotPassword extends Component {
  state = {
    admin: {
      handle: "",
    },
    errors: {},
    isDirty: {
      handle: false,
    },
    loading: false,
  };
  /**
   * To go back to login
   */
  _backToLogin = () => {
    this.props.history.push("login");
  };

  /**
   * To handle in input change and set the value to state
   */
  _handleOnChange = ({ currentTarget }) => {
    const { admin, isDirty } = this.state;
    admin[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ admin }, () => {
      this._validateForm();
    });
  };

  /**
   * To handle the form submit and validate it
   */
  _handleOnSubmit = (event) => {
    if (event) event.preventDefault();
    const { isDirty } = this.state;
    isDirty.handle = true;
    this.setState({ isDirty });
    let errors = this._validateForm();
    if (!errors) {
      console.log("Make API call");
      this._fotgotPassword();
    }
  };

  /**
   * To validate the form
   */
  _validateForm() {
    const { admin, errors, isDirty } = this.state;
    Object.keys(admin).forEach((each) => {
      if (each === "handle" && isDirty.handle) {
        if (!admin.handle.trim().length) {
          errors.handle = "Email is Required";
        } else if (
          admin.handle.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(admin.handle)
        ) {
          errors["handle"] = "Please enter valid Email";
        } else {
          delete errors[each];
          isDirty.handle = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  /**
   * Make forgot password api call
   */
  _fotgotPassword = () => {
    this.setState({ loading: true });

    fotgotPassword(this.state.admin)
      .then((response) => {
        showToast("Please check your email for futher instructions", "success");
        this.setState({ loading: false });
        this._backToLogin();
      })
      .catch((error) => {
        console.log("error>>", error);
        this.setState({ loading: false });
        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      });
  };

  render() {
    return (
      <div data-test="page-forgot-password" className="authWrapper">
        <div className="leftSide"></div>
        <div className="rightSide">
          <div className="loginWrapper">
            <img
              src={config.APP_LOGO}
              alt="Project Logo"
              className="projectLogo mt-0"
            />

            <h4>Forgot Password</h4>
            <Form onSubmit={(e) => this._handleOnSubmit(e)}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  data-test="email-input-box"
                  autoComplete="Email"
                  placeholder="Email"
                  type="email"
                  className="form-control"
                  name="handle"
                  onChange={this._handleOnChange}
                  value={this.state.admin.handle}
                />
                {this.state.errors.handle ? (
                  <div data-test="email-error-div" className="form-error">
                    {this.state.errors.handle}
                  </div>
                ) : null}
              </FormGroup>
              <Button
                type="submit"
                color="primary"
                className="d-block w-100 mt-4"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <i className="fa fa-spinner fa-spin text-white mr-1" />
                ) : null}{" "}
                Forgot Password
              </Button>
            </Form>
            <Button
              color="link"
              className="mt-2"
              onClick={() => this._backToLogin()}
            >
              Back to Login
            </Button>
          </div>
          <div className="loginFooter">
            <span>
              {" "}
              &copy; 2010 - 2020 {config.APP_NAME} All Rights Reserved. Design
              by Logic Square
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
