import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  ModalFooter,
} from "reactstrap";
import { createService, editService } from "../../http/http-calls";
// import RangeSlider from "../RangeSlider";
import { showToast } from "../../helper-methods";

const AddServices = ({
  isOpen,
  toggle,
  isEdit,
  editDetails,
  fetchAllServices,
  fetchAllCategory,
}) => {
  const _closeModal = () => {
    setFormData({
      serviceName: "",
      serviceType: "",
      healthyRatio: "",
    });
    setIsDirty({
      serviceName: false,
      serviceType: false,
      healthyRatio: false,
    });
    setErrors({});
    toggle();
  };

  useEffect(() => {
    if (editDetails !== null) {
      setFormData({
        serviceName: editDetails?.serviceName,
        serviceType: editDetails?.serviceType,
        healthyRatio: editDetails?.healthyRatio,
      });
      setPrevData({
        serviceName: editDetails?.serviceName,
        serviceType: editDetails?.serviceType,
        healthyRatio: editDetails?.healthyRatio,
      });
    }
  }, [editDetails]);
  //state
  const [formData, setFormData] = useState({
    serviceName: "",
    serviceType: "",
    healthyRatio: "",
  });

  const [prevData, setPrevData] = useState({
    serviceName: "",
    serviceType: "",
    healthyRatio: "",
  });

  const [isDirty, setIsDirty] = useState({
    serviceName: false,
    serviceType: false,
    healthyRatio: false,
  });

  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);

  const _addServiceHandler = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    const error = _validateForm(formData, newIsDirty);
    console.log("errors => ", error);
    if (!error) {
      console.log(">>>", formData);
      setFlag(true);

      if (editDetails) {
        let service = formData.serviceName.split(" ");
        let array = [];
        service.forEach((data) => {
          return array.push(data.slice(0, 1).toUpperCase() + data.substring(1));
        });
        const serviceName = array.reduce(
          (accumulator, currentValue) => accumulator + " " + currentValue
        );
        let paylod = { ...formData, serviceName: serviceName };
        try {
          const res = await editService(editDetails?._id, paylod);
          console.log("serviceCreate", res);
          fetchAllServices();
          fetchAllCategory();
          showToast(
            res.message && res.message.length
              ? res.message
              : "Resource Updated Successfully!",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          showToast(
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        }
      } else {
        let service = formData.serviceName.split(" ");
        let array = [];
        service.forEach((data) => {
          return array.push(data.slice(0, 1).toUpperCase() + data.substring(1));
        });
        const serviceName = array.reduce(
          (accumulator, currentValue) => accumulator + " " + currentValue
        );
        let paylod = { ...formData, serviceName: serviceName };
        try {
          const res = await createService(paylod);
          console.log("serviceCreate", res);
          fetchAllServices();
          fetchAllCategory();
          showToast(
            res.message && res.message.length
              ? res.message
              : "Resource Created Successfully!",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          showToast(
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        }
      }
      setFlag(false);
      _closeModal();
    }
  };
  //onChange
  const _handelOnChange = (field, value) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };

    newFormData[field] = value;

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
    _validateForm(newFormData, newIsDirty);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "serviceName" && dirties[each]) {
        if (!data.serviceName.trim()) {
          newErrors.serviceName = "*Required";
        } else if (data.serviceName.trim().length > 200) {
          newErrors.serviceName = "*Service allowed max 200 chars";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } 
      // else if (each === "serviceType" && dirties[each]) {
      //   if (!data.serviceType.trim()) {
      //     newErrors.serviceType = "*Required";
      //   } else if (data.serviceType.trim().length > 35) {
      //     newErrors.serviceType = "*Category allowed max 35 chars";
      //   } else {
      //     delete newErrors[each];
      //     dirties[each] = false;
      //   }
      // } else if (each === "healthyRatio" && dirties[each]) {
      //   if (!data?.healthyRatio) {
      //     newErrors.healthyRatio = "*Required";
      //   } else {
      //     delete newErrors[each];
      //     dirties[each] = false;
      //   }
      // }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  // const handleClick = (value) => {
  //   console.log("value", value);
  //   _handelOnChange("healthyRatio", value);
  // };
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        className="modal-dialog-centered"
      >
        <ModalHeader>{isEdit ? "Edit Resource " : "Add Resource"}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Resource</Label>
            <Input
              placeholder="Enter"
              value={formData?.serviceName}
              onChange={(event) =>
                _handelOnChange("serviceName", event.target.value)
              }
            />
            {errors.serviceName ? (
              <div className="form-error">{errors.serviceName}</div>
            ) : null}
          </FormGroup>

          {/*<FormGroup>
            <Label>Category</Label>
            <Input
              placeholder="Enter"
              // value={formData?.serviceType}
              onChange={(event) =>
                _handelOnChange("serviceType", event.target.value)
              }
            />
            {errors.serviceType ? (
              <div className="form-error">{errors.serviceType}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Healthy Ratio</Label>
            <RangeSlider
              handleClick={handleClick}
              value={editDetails ? formData?.healthyRatio : null}
            />
            <span className="note">
              <i className="fa fa-star"></i>
              No of veterans per vendor
            </span>
            {errors.healthyRatio ? (
              <div className="form-error">{errors.healthyRatio}</div>
            ) : null}
          </FormGroup>*/}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={() => _addServiceHandler()}
            disabled={
              isEdit
                ? JSON.stringify(prevData) === JSON.stringify(formData)
                : flag === true
            }
          >
            {isEdit ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddServices;
