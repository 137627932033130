import OneSignal from "react-onesignal";

export { default as OneSignalHelper } from "./onesignalHelper";

export default async function runOneSignal() {
  await OneSignal.init({
    appId: "45d55381-0e23-4ec8-938b-97baa6ce4e73",
    safari_web_id: "web.onesignal.auto.31ca14f2-9a64-44ce-b1fb-164017f55430",
    notifyButton: {
      enable: true,
    },
    allowLocalhostAsSecureOrigin: true,
  });
}
