import React, { useState } from "react";
import { Collapse, CardBody, Card, CardHeader, CustomInput } from "reactstrap";
import Select from "react-select";

const AccordioanWrap = ({ formData, _checkBoxHandler, handleCountryListChange, countyList }) => {
  const [openCollapseId, setOpenCollapseId] = useState(null);

  const accordionItems = [
    {
      id: "resource",
      title: "Resources",
      items: [
        { title: "View Resources", key: "view", disabled: false },
        { title: "Add New Resources", key: "add", disabled: !formData?.resource?.view },
        { title: "Edit and Delete Resources", key: "edit", disabled: !formData?.resource?.view }
      ]
    },
    {
      id: "team",
      title: "Team-Member",
      items: [
        { title: "View Team Member", key: "view", disabled: false },
        { title: "Add New Team Member", key: "add", disabled: !formData?.team?.view },
        { title: "Edit and Delete Team Member", key: "edit", disabled: !formData?.team?.view }
      ]
    },
    {
      id: "provider",
      title: "Provider-List",
      items: [
        { title: "View Provider List", key: "view", disabled: false },
        { title: "Add New Provider List", key: "add", disabled: !formData?.provider?.view },
        { title: "Edit and Delete Provider List", key: "edit", disabled: !formData?.provider?.view },
        { title: "Accept/Reject Provider's Request", key: "acceptReject", disabled: !formData?.provider?.view }
      ]
    },
    {
      id: "isAllCounty",
      title: "County",
      items: [
        { title: "Show All", key: "All", disabled: false },
      ]
    }
  ];

  const toggleCollapse = (collapseId) => {
    setOpenCollapseId(openCollapseId === collapseId ? null : collapseId);
  };

  return (
    <>
      {accordionItems.map((accordion, index) => (
        <Card
          key={index}
          className={`accordianWrap ${
            openCollapseId === accordion.id ? "open" : ""
          }`}
        >
          <CardHeader onClick={() => toggleCollapse(accordion.id)}>
            {accordion.title}
            <span>
              <i className="fa fa-chevron-down"></i>
            </span>
          </CardHeader>
          <Collapse isOpen={openCollapseId === accordion.id}>
            <CardBody>
              {accordion.items.map((item, itemIndex) => (
                <CustomInput
                  key={itemIndex}
                  type="checkbox"
                  label={item.title}
                  value={item.title}
                  id={`${accordion.id}-${itemIndex}`}
                  disabled={item.disabled}
                  checked={accordion.id === "isAllCounty" ? formData?.[accordion.id] : formData?.[accordion.id]?.[item.key]}
                  onClick={(event) => _checkBoxHandler(accordion.id === "isAllCounty" ? accordion.id : (accordion.id).toLowerCase(), item.key, event)}
                  />
              ))}
              {!formData?.isAllCounty && accordion.id === "isAllCounty" && (
                <Select
                  closeMenuOnSelect={false}
                  value={formData?.county?.map((data) => ({
                    label: data,
                    value: data,
                  }))}
                  isMulti
                  options={countyList}
                  onChange={handleCountryListChange}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select all that apply"
                />
              )}
            </CardBody>
          </Collapse>
        </Card>
      ))}
    </>
  );
};

export default AccordioanWrap;
