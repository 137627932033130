import React, { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  ModalFooter,
  ButtonDropdown,
  Col,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const NewEmailTemplate = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalHeader>New Text Templates</ModalHeader>
        <ModalBody>
          <Row form>
            <Col md={6}>
              <FormGroup className="mb-3">
                <Label for="TemplateName">Template Name</Label>
                <Input
                  type="text"
                  name="name"
                  // id="TemplateName"
                  // value={emailTemplateFields.name}
                  // onChange={(e) => _handleTemplateFieldChange(e)}
                />

                {/* <div className="form-error"> {errors.name} </div> */}

                {/* <div className="form-error"> Required </div> */}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-3">
                <Label for="Type">Category</Label>
                <Input type="select" name="Type" id="Type">
                  <option value="" disabled>
                    -Select-
                  </option>
                  <option value="Pre Quote">Pre Quote</option>
                  <option value="Quote">Quote</option>
                </Input>
                {/* <div className="form-error"> Required </div> */}
              </FormGroup>
            </Col>
          </Row>

          <FormGroup row className="mb-3">
            <Col lg={12} md={12}>
              <div className="variables_textarea">
                <Label className="mb-0">Subject</Label>

                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>Insert variables</DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>{`user-email`}</DropdownItem>
                    <DropdownItem>{`user-name`}</DropdownItem>
                    <DropdownItem>{`user-phone`}</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              <Input
                type="textarea"
                name="bodyText"
                style={{ height: 90 }}
                // value={smsData.bodyText}
                // onChange={(e) => _handleChange(e)}
              />

              {/* <div className="d-flex justify-content-between">

                <span className="error-message">
                  <div className="form-error"> required </div>
                </span>

                <div className="form-error text-right w-100">
                  0
                </div>
              </div> */}
            </Col>

            {/* <div className="form-error"> Required </div> */}
          </FormGroup>

          <FormGroup row className="mb-3">
            <Col lg={12} md={12}>
              <div className="variables_textarea">
                <Label className="mb-0">Body</Label>

                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>Insert variables</DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>{`user-email`}</DropdownItem>

                    <DropdownItem>{`user-name`}</DropdownItem>

                    <DropdownItem>{`user-phone`}</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              <Input
                type="textarea"
                name="bodyText"
                style={{ height: 90 }}
                // value={smsData.bodyText}
                // onChange={(e) => _handleChange(e)}
              />

              {/* <div className="d-flex justify-content-between">

                <span className="error-message">
                  <div className="form-error"> required </div>
                </span>

                <div className="form-error text-right w-100">
                  0
                </div>
              </div> */}
            </Col>

            {/* <div className="form-error"> Required </div> */}
          </FormGroup>

          <Label>Attachment</Label>
          <div className="d-flex align-items-center mb-3">
            <Input
              type="file"
              name="Attachments"
              id="Attachments"
              className="d-none"
              // onChange={_handleFileUpload}
              // disabled={attachmentName.length > 0 ? true : false}
            />
            <Label className="uplaodFiled" for="Attachments">
              <div className="uplaod_inner">
                <i className="fa fa-plus"></i>
                <span>Upload</span>
              </div>
            </Label>

            {/* <div className="form-error"> Required </div> */}

            {/* Multiple Attachments */}

            <div className="d-flex attachment_list">
              <div className="attachmentWrapper">
                <div className="attachmentFile">
                  <i className="fa pdf_file fa-file-pdf-o" />
                  <i
                    className="fa fa-times removeattachment"
                    // onClick={(e) => _handleRemoveAttachment(e)}
                  />
                </div>
                <span>Invoice Invoice Invoice</span>
              </div>
              <div className="attachmentWrapper">
                <div className="attachmentFile">
                  <i class="fa  doc_file fa-file-word-o"></i>
                  <i
                    className="fa fa-times removeattachment"
                    // onClick={(e) => _handleRemoveAttachment(e)}
                  />
                </div>
                <span>Invoice Invoice Invoice</span>
              </div>
              <div className="attachmentWrapper">
                <div className="attachmentFile">
                  <img
                    src={require("../../assets/img/user.jpg")}
                    alt="default"
                  />
                  <i
                    className="fa fa-times removeattachment"
                    // onClick={(e) => _handleRemoveAttachment(e)}
                  />
                </div>
                <span>Invoice Invoice Invoice</span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              props.toggle();
            }}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={() => {
              props.toggle();
            }}
          >
            Save Template
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewEmailTemplate;
