import React from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";

const ProviderView = ({ isOpen, toggle, viewCountyService }) => {
  console.log("props", viewCountyService);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalHeader>Resources List</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <div className="serviceList">
                <h2>Resources</h2>
                <ul>
                  {viewCountyService?._service?.map((each, index) => (
                    <li key={index}>{each?.serviceName}</li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="serviceList">
                <h2>Counties</h2>
                <ul>
                  {viewCountyService?._county?.map((each, index) => (
                    <li key={index}>{each?.countyName}</li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProviderView;
