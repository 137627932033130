import React, { useState } from "react";
import {
  Button,
  Form,
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { addDeviceId, login } from "../http/http-calls";
import { showToast } from "../helper-methods";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { addUserCredential } from "../redux/actions/user-credential";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    handle: "",
    password: "",
  });
  const [isDirty, setIsDirty] = useState({
    handle: false,
    password: false,
  });
  const [errors, setErrors] = useState({});
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const _handleOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };
    newFormData[field] = event.target.value;
    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
    _validateForm(newFormData, newIsDirty);
  };

  const _handleOnSubmit = async (event) => {
    event.preventDefault();
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    const error = _validateForm(formData, newIsDirty);
    console.log("errors => ", error);
    if (!error) {
      console.log("formData", formData);
      setLoading(true);
      try {
        const res = await login(formData);
        console.log("login>>", res);
        showToast("Successfully logged in!", "success");

        const token = res.token;
        let user = jwt_decode(token);
        console.log(user);
        dispatch(
          addUserCredential({
            token: res.token,
            user: { ...user, ...res?.data },
          })
        );
        history.push("/home");

        const deviceIdData = JSON.parse(localStorage.getItem("users"));

        const { deviceId } = deviceIdData?.[deviceIdData?.length - 1] || {};
        const deviceRes = await addDeviceId({ deviceId: deviceId });
        console.log("deviceRes", deviceRes);
      } catch (error) {
        console.log("error>>", error);
        if(error?.link){
          Swal.fire({
            title: "",
            text: `It seems you are in the wrong link!`,
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Please click here",
          }).then((result) => {
            if(result?.isConfirmed){
              window.location.href = error?.link
            }
          });
        }

        showToast(
          error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
      }
      setLoading(false);
    }
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };
    // console.log(admin, isDirty);
    Object.keys(dirties).forEach((each) => {
      if (each === "handle" && dirties[each]) {
        if (!data.handle.trim().length) {
          newErrors.handle = "Email is Required";
        } else if (
          data.handle.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(data.handle)
        ) {
          newErrors.handle = "Please enter valid Email";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "password" && dirties[each]) {
        if (!data.password.trim().length) {
          // console.log(admin.password);
          newErrors.password = "Password is Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  return (
    <div className="authWrapper" data-test="page-login">
      <div className="leftSide"></div>
      <div className="rightSide">
        <div className="loginWrapper">
          <img
            src={require("../assets/img/IDVA logo-01.png")}
            alt="Project Logo"
            className="projectLogo"
          />
          <h4>Login to Your Account</h4>
          <Form data-test="login-form" noValidate>
            <FormGroup className="mb-4">
              <Label>Email Address</Label>
              <Input
                data-test="email-input-box"
                type="email"
                placeholder="Email"
                autoComplete="off"
                name="handle"
                onChange={(event) => _handleOnChange("handle", event)}
              />
              {errors.handle ? (
                <div data-test="email-error-div" className="form-error">
                  {errors.handle}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup className="mb-4">
              <Label>Password</Label>
              <InputGroup>
                <Input
                  data-test="password-input-box"
                  type={`${isShowPassword ? "text" : "password"}`}
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  onChange={(event) => _handleOnChange("password", event)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  >
                    {isShowPassword ? (
                      <i className="fa fa-eye" />
                    ) : (
                      <i className="fa fa-eye-slash" />
                    )}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              <div className="d-flex justify-content-between">
                {errors.password ? (
                  <div data-test="password-error-div" className="form-error">
                    {errors.password}
                  </div>
                ) : null}
                <span
                  className="forgotPassword"
                  onClick={() => history.push("/forgot-password")}
                >
                  Forgot Password?
                </span>
              </div>
            </FormGroup>

            <Button
              data-test="login-button"
              type="submit"
              color="primary"
              className="d-block w-100 mt-4"
              disabled={loading}
              onClick={(event) => _handleOnSubmit(event)}
            >
              {loading ? (
                <i className="fa fa-spinner text-white mr-1 fa-spin" />
              ) : null}
              Login
            </Button>
          </Form>
        </div>
        <div className="loginFooter">
          <div className="footerLogo">
            <img src={require("../assets/img/footer_logo.png")} alt="logo" />

            <a
              href="https://988lifeline.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              988
            </a>
            <a
              href="https://www.facebook.com/indiana.veterans/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
          <div className="footerBottom">
            <div className="copyright">
              &copy; 2024 Military Veteran Community Resources{" "}
            </div>
            <div className="poweredBy">
              Powered by{" "}
              <a
                href="https://www.logic-square.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Logic Square
              </a>
            </div>
          </div>

          {/* <div>
            <span className="powered-by d-block">
              &copy; 2024 Powered by{" "}
              <a
                href="https://www.logic-square.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Logic Square
              </a>
            </span>
          </div> */}
          {/* <div className="text-end">
            <span>
              <a
                href="https://www.facebook.com/indiana.veterans/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </span>
            <span className="separator"> &nbsp;|&nbsp; </span>
            <span>
              <a
                href="https://988lifeline.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                988
              </a>
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
