// app version
export const APP_VERSION = "2.0.0";

export default {
  creatorFrontendURL:
    process.env.REACT_APP_BACKEND_ENV === "live"
      ? "https://creator.vewmee.com/"
      : "https://sc-vewmee-creator.surge.sh/",

  baseURL:
    process.env.REACT_APP_BACKEND_ENV === "live"
      ? "https://api.vewmee.com/admin/v1/"
      // : "https://api-sv.logic-square.com/admin/",
      : "https://api.mvcrconnect.com/admin/",

  AWS_IMAGE_BUCKET_NAME:
    process.env.REACT_APP_BACKEND_ENV === "live"
      ? "wl-public-contents"
      : "sc-public-contents",

  APP_LOGO: "/IDVA logo-01.png",

  APP_NAME: "Stronger Vet.",

  TOKEN_NAME: "vewmeeAdminToken",

  currencySymbol: "$",

  cloudinaryURL:
    "https://api.cloudinary.com/v1_1/www-logic-square-com/auto/upload?upload_preset=yu5mntw0", // for dev

  defaultUserPicture: require("../assets/img/default_user_icon.svg"),
  defaultCoverPicture:
    "https://sc-default-content.s3.amazonaws.com/DEFAULT_COVER_PICTURE.png",
  noImageAvialableUrl:
    "https://tf-prod-images.s3.us-east-2.amazonaws.com/NO_IMAGE_AVAILABLE.png",

  leftMenu: {
    items: [
      {
        name: "Dashboard",
        url: "/home",
        // icon: "fa fa-dashboard",
        icon: {
          img: {
            src: require("../assets/img/dashboard.png"),
          },
        },
        isHeaderMenu: true,
      },
      {
        name: "Resources",
        url: "/services",
        icon: {
          img: {
            src: require("../assets/img/resource.png"),
          },
        },
        isHeaderMenu: true,
      },
      {
        name: "Team",
        url: "/team",
        icon: {
          img: {
            src: require("../assets/img/team.png"),
          },
        },
        isHeaderMenu: true,
      },
      {
        name: "Provider List ",
        url: "/provide-list",
        icon: {
          img: {
            src: require("../assets/img/providerList.png"),
          },
        },
        isHeaderMenu: true,
      },
      {
        name: "Setting",
        url: "/settings",
        icon: {
          img: {
            src: require("../assets/img/setting.png"),
          },
        },
        isHeaderMenu: false,
      },
      {
        name: "Notification ",
        url: "/Notification",
        icon: {
          img: {
            src: require("../assets/img/notification.png"),
          },
        },
        isHeaderMenu: false,
      },
    ],
  },
  subscriberCounts: [
    { label: "0", value: { min: 0, max: 0 } },
    { label: "1-5", value: { min: 1, max: 5 } },
    { label: "6-10", value: { min: 6, max: 10 } },
    { label: "11-50", value: { min: 11, max: 50 } },
    { label: "51-100", value: { min: 51, max: 100 } },
    { label: "101-500", value: { min: 101, max: 500 } },
    { label: "500+", value: { min: 500 } },
  ],
  invitationsStatusColor: {
    pending: "warning",
    accepted: "success",
    expired: "danger",
  },
  contentModerationStatusList: [
    "User Blocked",
    "Content within guidelines",
    "Content Removed",
    "Pending Inquiry",
  ],
  contentModerationReasonList: [
    "Explicit Content",
    "Hate Speech",
    "Promotes Illegal Activity",
    "Racism",
    "Violence",
    "Other",
  ],
  subscriptionCounts: [
    { label: "0", value: { min: 0, max: 0 } },
    { label: "1", value: { min: 1, max: 1 } },
    { label: "2", value: { min: 2, max: 2 } },
    { label: "3-5", value: { min: 3, max: 5 } },
    { label: "6-10", value: { min: 6, max: 10 } },
    { label: "10+", value: { min: 10 } },
  ],
  spentCounts: [
    { label: "0", value: { min: 0, max: 0 } },
    { label: "1-50", value: { min: 1, max: 50 } },
    { label: "51-200", value: { min: 51, max: 200 } },
    { label: "201-500", value: { min: 201, max: 500 } },
    { label: "500+", value: { min: 500 } },
  ],
  attendingCounts: [
    { label: "0", value: { min: 0, max: 0 } },
    { label: "1-50", value: { min: 1, max: 50 } },
    { label: "51-200", value: { min: 51, max: 200 } },
    { label: "201-500", value: { min: 201, max: 500 } },
    { label: "500+", value: { min: 500 } },
  ],
  priceCounts: [
    { label: "0-20", value: { min: 0, max: 20 } },
    { label: "20-50", value: { min: 20, max: 50 } },
    { label: "50-100", value: { min: 50, max: 100 } },
    { label: "100-250", value: { min: 100, max: 250 } },
    { label: "250-500", value: { min: 250, max: 500 } },
    { label: "500+", value: { min: 500 } },
  ],
  salesCounts: [
    { label: "0", value: { min: 0, max: 0 } },
    { label: "1-5", value: { min: 1, max: 50 } },
    { label: "6-10", value: { min: 6, max: 10 } },
    { label: "11-20", value: { min: 11, max: 20 } },
    { label: "21-50", value: { min: 21, max: 50 } },
    { label: "51-100", value: { min: 51, max: 100 } },
    { label: "100+", value: { min: 100 } },
  ],
  paymentTypes: [
    "tips",
    "subscription",
    // "post",
    "message",
    "withdrawal",
    "vault",
    "unlock",
    "payperview",
    "chargeback",
    "refund",
    "liveEvent",
    // "remove post"
  ],
  liveStatusList: ["Scheduled", "Canceled", "Ongoing", "Completed"],
  paymentStatusList: ["pending", "released", "approved", "flagged", "refunded"],
  withdrawalStatus: [
    "accepted",
    "requested",
    "rejected",
    "pending",
    "paid",
    "failed",
    "canceled",
    "updated",
  ],
  monthList: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  regexConfig: {
    digitOnly: /^[0-9]*$/,
    phone: /^(\+\d{1,3}[- ]?)?\d{8,11}$/,
    email:
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    url: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  },
  rsvp: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  socialIconConfig: {
    facebook: "fa fa-facebook",
    twitter: "fa fa-twitter",
    youtube: "fa fa-youtube-play",
    instagram: "fa fa-instagram",
    tiktok: "fa fa-tiktok",
  },
  county: [
    "Adams",
    "Allen",
    "Bartholomew",
    "Benton",
    "Blackford",
    "Boone",
    "Brown",
    "Carroll",
    "Cass",
    "Clark",
    "Clay",
    "Clinton",
    "Crawford",
    "Daviess",
    "Dearborn",
    "Decatur",
    "DeKalb",
    "Delaware",
    "Dubois",
    "Elkhart",
    "Fayette",
    "Floyd",
    "Fountain",
    "Franklin",
    "Fulton",
    "Gibson",
    "Grant",
    "Greene",
    "Hamilton",
    "Hancock",
    "Harrison",
    "Hendricks",
    "Henry",
    "Howard",
    "Huntington",
    "Jackson",
    "Jasper",
    "Jay",
    "Jefferson",
    "Jennings",
    "Johnson",
    "Knox",
    "Kosciusko",
    "LaGrange",
    "Lake",
    "LaPorte",
    "Lawrence",
    "Madison",
    "Marion",
    "Marshall",
    "Martin",
    "Miami",
    "Monroe",
    "Montgomery",
    "Morgan",
    "Newton",
    "Noble",
    "Ohio",
    "Orange",
    "Owen",
    "Parke",
    "Perry",
    "Pike",
    "Porter",
    "Posey",
    "Pulaski",
    "Putnam",
    "Randolph",
    "Ripley",
    "Rush",
    "St. Joseph",
    "Scott",
    "Shelby",
    "Spencer",
    "Starke",
    "Steuben",
    "Sullivan",
    "Switzerland",
    "Tippecanoe",
    "Tipton",
    "Union",
    "Vanderburgh",
    "Vermillion",
    "Vigo",
    "Wabash",
    "Warren",
    "Warrick",
    "Washington",
    "Wayne",
    "Wells",
    "White",
    "Whitley",
  ],
};
