export const countyNameToId = {
  Adams: "18001",
  Allen: "18003",
  Bartholomew: "18005",
  Benton: "18007",
  Blackford: "18009",
  Boone: "18011",
  Brown: "18013",
  Carroll: "18015",
  Cass: "18017",
  Clark: "18019",
  Clay: "18021",
  Clinton: "18023",
  Crawford: "18025",
  Daviess: "18027",
  Dearborn: "18029",
  Decatur: "18031",
  DeKalb: "18033",
  Delaware: "18035",
  Dubois: "18037",
  Elkhart: "18039",
  Fayette: "18041",
  Floyd: "18043",
  Fountain: "18045",
  Franklin: "18047",
  Fulton: "18049",
  Gibson: "18051",
  Grant: "18053",
  Greene: "18055",
  Hamilton: "18057",
  Hancock: "18059",
  Harrison: "18061",
  Hendricks: "18063",
  Henry: "18065",
  Howard: "18067",
  Huntington: "18069",
  Jackson: "18071",
  Jasper: "18073",
  Jay: "18075",
  Jefferson: "18077",
  Jennings: "18079",
  Johnson: "18081",
  Knox: "18083",
  Kosciusko: "18085",
  LaGrange: "18087",
  Lake: "18089",
  LaPorte: "18091",
  Lawrence: "18093",
  Madison: "18095",
  Marion: "18097",
  Marshall: "18099",
  Martin: "18101",
  Miami: "18103",
  Monroe: "18105",
  Montgomery: "18107",
  Morgan: "18109",
  Newton: "18111",
  Noble: "18113",
  Ohio: "18115",
  Orange: "18117",
  Owen: "18119",
  Parke: "18121",
  Perry: "18123",
  Pike: "18125",
  Porter: "18127",
  Posey: "18129",
  Pulaski: "18131",
  Putnam: "18133",
  Randolph: "18135",
  Ripley: "18137",
  Rush: "18139",
  "St. Joseph": "18141",
  Scott: "18143",
  Shelby: "18145",
  Spencer: "18147",
  Starke: "18149",
  Steuben: "18151",
  Sullivan: "18153",
  Switzerland: "18155",
  Tippecanoe: "18157",
  Tipton: "18159",
  Union: "18161",
  Vanderburgh: "18163",
  Vermillion: "18165",
  Vigo: "18167",
  Wabash: "18169",
  Warren: "18171",
  Warrick: "18173",
  Washington: "18175",
  Wayne: "18177",
  Wells: "18179",
  White: "18181",
  Whitley: "18183",
};
