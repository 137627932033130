import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  // makeDeleteRequests,
  makeDeleteRequest,
} from "./http-service";
import config from "../config/index";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "login", false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const spoofCreatorAccountLogin = (creatorId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + `spoof/login/${creatorId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fotgotPassword = ({ handle }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "forgotpassword", false, {
      handle,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
//fetch user daetails
export const fetchUserDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "user/detail", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//create team members
export const createTeam = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "user", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editTeamMember = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "user/update/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const userStatus = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "user/update/status/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllMembers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "users", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteOneMember = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "user/delete/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Message Templates
export const fetchAllTemplates = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "templates", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//service
export const fetchServices = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "services", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const fetchCategories = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "service/list", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createService = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "service", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editService = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "service/update/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteOneService = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "service/delete/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const serviceStatus = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      config.baseURL + "service/update/status/" + id,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//county list
export const fetchCounty = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "counties", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//provider
export const fetchProvider = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "providers", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createProvider = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "provider", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editProvider = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "provider/update/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteOneProvider = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(config.baseURL + "provider/delete/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const providerStatus = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "provider/status/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const providerRequest = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "provider/approve/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllServices = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "service/all", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const duplicate = (field, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "duplicate/check/" + field, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchNotes = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "notes", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addNote = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "note/add", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editNote = ( id, payload ) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "note/update/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteNote = ( id, payload ) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "note/delete/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//veterans

export const fetchVeterans = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "veterans", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//dashboard
export const getAllDashboardData = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "dashboard", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLastWeekdData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "stats/week", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLastMonthData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "stats/month", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getQtdData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "stats/qtd", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getYtdData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "stats/ytd", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const dashboardServiceFilter = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "dashboard/service/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getManualStats = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "stats/manual", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};


//noyification

export const fetchAllNotifications = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "notifications", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addDeviceId = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "device/add", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const notificationsRead = () => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "/notification/read", true )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};


//dont know section
export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "user", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllCreators = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "influencers", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changeUserStatus = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "change/status/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changeVaultStatus = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "folder/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCreatorDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "influencer/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllSubscribers = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "fans", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSubscriberDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "fan/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAdminUsers = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "admins", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changeAdminStatus = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "admin/status/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createAdmin = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "create/admin", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllInvitations = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "invitations", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getInvitationRequests = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "invitationRequests", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendInvite = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "invitation", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getContentModerations = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "reports", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateContentModeration = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "report/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllTransactions = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "transactions", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateTransactions = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "transactions", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const onUpdatePPVStatus = (data) => {
  console.log(data);
  let payload = {
    status: data.status,
  };
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "payperview/" + data.id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "settings", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateSettings = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "settings", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Edit Setting's for TemplateName
export const updateTemplateName = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "settings/templatename", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllPayouts = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "withdrawals", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updatePayout = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "withdrawal/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendMessage = (option, data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "users/emails/" + option, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getDashboardSummary = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "dashboard/summary", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getTopCreators = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "dashboard/influencer/summary", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getTopSubscriber = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "dashboard/fan/summary", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCreatorsPerMonth = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "dashboard/influencer/month", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSubscribersPerMonth = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "dashboard/fan/month", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getCreatorActivity = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "influencer/activity/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "post/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getComments call error: ", e);
        reject(e);
      });
  });
};

export const getSubscriberActivity = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "fan/activity/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateInfluencerDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "influencer/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const acceptOrRejectInvitationRequest = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "invitationRequest/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const flagPost = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "report", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("flagPost call error: ", e);
        reject(e);
      });
  });
};

export const getPostDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "post/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllUsers = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "users", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLiveEvents = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "events", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getLiveEventDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "event/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllPPVs = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "payperviews", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getPPVDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "payperview/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllVaultFolder = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "folders", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllVaultFolderDetail = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "folder/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllShoutout = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "shoutOutOrders", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateShoutoutOrder = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + `shoutOutOrder/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getInfluencerSubscriptions = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "influencer/subscriptions/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getInfluencerInvitations = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "influencer/invitations/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getFanSubscriptions = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "fan/subscriptions/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAgencies = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "agencies", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getOneAgency = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseURL + "agency/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgencyDetail = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + "agency/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendInvitaionToAgency = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseURL + "invitation/agency", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const resendReferralInvitation = (userId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseURL + `invitation/resend/${userId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("forgotPassword call error: ", e);
        reject(e);
      });
  });
};
