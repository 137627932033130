export const initialStateSpecificData = {
  18001: {
    name: "Adams",
  },
  18003: {
    name: "Allen",
  },
  18005: {
    name: "Bartholomew",
  },
  18007: {
    name: "Benton",
  },
  18009: {
    name: "Blackford",
  },
  18011: {
    name: "Boone",
  },
  18013: {
    name: "Brown",
  },
  18015: {
    name: "Carroll",
  },
  18017: {
    name: "Cass",
  },
  18019: {
    name: "Clark",
  },
  18021: {
    name: "Clay",
  },
  18023: {
    name: "Clinton",
  },
  18025: {
    name: "Crawford",
  },
  18027: {
    name: "Daviess",
  },
  18029: {
    name: "Dearborn",
  },
  18031: {
    name: "Decatur",
  },
  18033: {
    name: "DeKalb",
  },
  18035: {
    name: "Delaware",
  },
  18037: {
    name: "Dubois",
  },
  18039: {
    name: "Elkhart",
  },
  18041: {
    name: "Fayette",
  },
  18043: {
    name: "Floyd",
  },
  18045: {
    name: "Fountain",
  },
  18047: {
    name: "Franklin",
  },
  18049: {
    name: "Fulton",
  },
  18051: {
    name: "Gibson",
  },
  18053: {
    name: "Grant",
  },
  18055: {
    name: "Greene",
  },
  18057: {
    name: "Hamilton",
  },
  18059: {
    name: "Hancock",
  },
  18061: {
    name: "Harrison",
  },
  18063: {
    name: "Hendricks",
  },
  18065: {
    name: "Henry",
  },
  18067: {
    name: "Howard",
  },
  18069: {
    name: "Huntington",
  },
  18071: {
    name: "Jackson",
  },
  18073: {
    name: "Jasper",
  },
  18075: {
    name: "Jay",
  },
  18077: {
    name: "Jefferson",
  },
  18079: {
    name: "Jennings",
  },
  18081: {
    name: "Johnson",
  },
  18083: {
    name: "Knox",
  },
  18085: {
    name: "Kosciusko",
  },
  18087: {
    name: "Lagrange",
  },
  18089: {
    name: "Lake",
  },
  18091: {
    name: "Laporte",
  },
  18093: {
    name: "Lawrence",
  },
  18095: {
    name: "Madison",
  },
  18097: {
    name: "Marion",
  },
  18099: {
    name: "Marshall",
  },
  18101: {
    name: "Martin",
  },
  18103: {
    name: "Miami",
  },
  18105: {
    name: "Monroe",
  },
  18107: {
    name: "Montgomery",
  },
  18109: {
    name: "Morgan",
  },
  18111: {
    name: "Newton",
  },
  18113: {
    name: "Noble",
  },
  18115: {
    name: "Ohio",
  },
  18117: {
    name: "Orange",
  },
  18119: {
    name: "Owen",
  },
  18121: {
    name: "Parke",
  },
  18123: {
    name: "Perry",
  },
  18125: {
    name: "Pike",
  },
  18127: {
    name: "Porter",
  },
  18129: {
    name: "Posey",
  },
  18131: {
    name: "Pulaski",
  },
  18133: {
    name: "Putnam",
  },
  18135: {
    name: "Randolph",
  },
  18137: {
    name: "Ripley",
  },
  18139: {
    name: "Rush",
  },
  18141: {
    name: "St. Joseph",
  },
  18143: {
    name: "Scott",
  },
  18145: {
    name: "Shelby",
  },
  18147: {
    name: "Spencer",
  },
  18149: {
    name: "Starke",
  },
  18151: {
    name: "Steuben",
  },
  18153: {
    name: "Sullivan",
  },
  18155: {
    name: "Switzerland",
  },
  18157: {
    name: "Tippecanoe",
  },
  18159: {
    name: "Tipton",
  },
  18161: {
    name: "Union",
  },
  18163: {
    name: "Vanderburgh",
  },
  18165: {
    name: "Vermillion",
  },
  18167: {
    name: "Vigo",
  },
  18169: {
    name: "Wabash",
  },
  18171: {
    name: "Warren",
  },
  18173: {
    name: "Warrick",
  },
  18175: {
    name: "Washington",
  },
  18177: {
    name: "Wayne",
  },
  18179: {
    name: "Wells",
  },
  18181: {
    name: "White",
  },
  18183: {
    name: "Whitley",
  },
};

export const simplemaps_statemap_mapdata = {
  main_settings: {
    //General settings
    width: "responsive", //'700' or 'responsive'
    background_color: "#FFFFFF",
    background_transparent: "yes",
    popups: "detect",

    //State defaults
    state_description: "",
    state_color: "#88A4BC",
    state_hover_color: "#3B729F",
    state_url: "",
    border_size: 1.5,
    border_color: "#ffffff",
    all_states_inactive: "no",
    all_states_zoomable: "yes",

    //Location defaults
    location_description: "",
    location_color: "#FF0067",
    location_opacity: 0.8,
    location_hover_opacity: 1,
    location_url: "",
    location_size: 20,
    location_type: "circle",
    location_border_color: "#FFFFFF",
    location_border: 2,
    location_hover_border: 2.5,
    all_locations_inactive: "no",
    all_locations_hidden: "no",

    //Label defaults
    label_color: "#ffffff",
    label_hover_color: "#ffffff",
    label_size: 12,
    label_font: "Arial",
    hide_labels: "no",
    hide_eastern_labels: false,

    //Zoom defaults
    manual_zoom: "no",
    back_image: "no",
    arrow_box: "no",
    navigation_size: "40",
    navigation_color: "#f7f7f7",
    navigation_border_color: "#636363",
    initial_back: "no",
    initial_zoom: -1,
    initial_zoom_solo: "no",
    region_opacity: 1,
    region_hover_opacity: 0.6,
    zoom_out_incrementally: "yes",
    zoom_percentage: 0.9,
    zoom_time: 0.5,

    //Popup settings
    popup_color: "white",
    popup_opacity: 0.9,
    popup_shadow: 1,
    popup_corners: 5,
    popup_font: "12px/1.5 Verdana, Arial, Helvetica, sans-serif",
    popup_nocss: "no",

    //Advanced settings
    div: "map",
    auto_load: "no",
    rotate: "0",
    url_new_tab: "yes",
    images_directory: "default",
    import_labels: "no",
    fade_time: 0.1,
    link_text: "",
    state_image_url: "",
    state_image_position: "",
    location_image_url: "",
  },
  state_specific: {},
  locations: {},
  labels: {
    0: {
      name: "Adams",
      parent_id: "18001",
      x: 771,
      y: 291.4,
    },
    1: {
      name: "Allen",
      parent_id: "18003",
      x: 741.6,
      y: 209,
    },
    2: {
      name: "Bartholomew",
      parent_id: "18005",
      x: 599.6,
      y: 650.3,
      size: 10,
    },
    3: {
      name: "Benton",
      parent_id: "18007",
      x: 343.5,
      y: 324.5,
    },
    4: {
      name: "Blackford",
      parent_id: "18009",
      x: 701.1,
      y: 356,
      size: 8,
    },
    5: {
      name: "Boone",
      parent_id: "18011",
      x: 484.9,
      y: 456,
    },
    6: {
      name: "Brown",
      parent_id: "18013",
      x: 538.9,
      y: 662.8,
    },
    7: {
      name: "Carroll",
      parent_id: "18015",
      x: 482.9,
      y: 336.3,
    },
    8: {
      name: "Cass",
      parent_id: "18017",
      x: 522.2,
      y: 279.7,
    },
    9: {
      name: "Clark",
      parent_id: "18019",
      x: 628.7,
      y: 819.6,
    },
    10: {
      name: "Clay",
      parent_id: "18021",
      x: 378,
      y: 602.2,
    },
    11: {
      name: "Clinton",
      parent_id: "18023",
      x: 484.3,
      y: 396,
    },
    12: {
      name: "Crawford",
      parent_id: "18025",
      x: 495.2,
      y: 860.6,
    },
    13: {
      name: "Daviess",
      parent_id: "18027",
      x: 383.9,
      y: 770.9,
    },
    14: {
      name: "Dearborn",
      parent_id: "18029",
      x: 767.5,
      y: 661.6,
      size: 10,
    },
    15: {
      name: "Decatur",
      parent_id: "18031",
      x: 674.9,
      y: 625,
    },
    16: {
      name: "DeKalb",
      parent_id: "18033",
      x: 756.7,
      y: 135.8,
    },
    17: {
      name: "Delaware",
      parent_id: "18035",
      x: 688.2,
      y: 414.1,
    },
    18: {
      name: "Dubois",
      parent_id: "18037",
      x: 417.8,
      y: 848.9,
    },
    19: {
      name: "Elkhart",
      parent_id: "18039",
      x: 604.9,
      y: 87.6,
    },
    20: {
      name: "Fayette",
      parent_id: "18041",
      x: 728.2,
      y: 556.9,
    },
    21: {
      name: "Floyd",
      parent_id: "18043",
      x: 596.9,
      y: 856.4,
    },
    22: {
      name: "Fountain",
      parent_id: "18045",
      x: 354,
      y: 449.8,
    },
    23: {
      name: "Franklin",
      parent_id: "18047",
      x: 750.1,
      y: 604,
    },
    24: {
      name: "Fulton",
      parent_id: "18049",
      x: 523.6,
      y: 218,
    },
    25: {
      name: "Gibson",
      parent_id: "18051",
      x: 296.1,
      y: 859.9,
    },
    26: {
      name: "Grant",
      parent_id: "18053",
      x: 641.7,
      y: 345.9,
    },
    27: {
      name: "Greene",
      parent_id: "18055",
      x: 396.4,
      y: 693.1,
    },
    28: {
      name: "Hamilton",
      parent_id: "18057",
      x: 570.1,
      y: 450.6,
    },
    29: {
      name: "Hancock",
      parent_id: "18059",
      x: 616.9,
      y: 509.6,
    },
    30: {
      name: "Harrison",
      parent_id: "18061",
      x: 557.6,
      y: 889,
    },
    31: {
      name: "Hendricks",
      parent_id: "18063",
      x: 488.1,
      y: 519.4,
    },
    32: {
      name: "Henry",
      parent_id: "18065",
      x: 690,
      y: 483.2,
    },
    33: {
      name: "Howard",
      parent_id: "18067",
      x: 572.1,
      y: 353.4,
    },
    34: {
      name: "Huntington",
      parent_id: "18069",
      x: 671.7,
      y: 271.5,
      size: 10,
    },
    35: {
      name: "Jackson",
      parent_id: "18071",
      x: 575.4,
      y: 723.6,
    },
    36: {
      name: "Jasper",
      parent_id: "18073",
      x: 380.8,
      y: 228,
    },
    37: {
      name: "Jay",
      parent_id: "18075",
      x: 757.6,
      y: 364,
    },
    38: {
      name: "Jefferson",
      parent_id: "18077",
      x: 686.9,
      y: 742.9,
    },
    39: {
      name: "Jennings",
      parent_id: "18079",
      x: 644.9,
      y: 699.4,
    },
    40: {
      name: "Johnson",
      parent_id: "18081",
      x: 561.2,
      y: 587.2,
    },
    41: {
      name: "Knox",
      parent_id: "18083",
      x: 327.5,
      y: 782.6,
    },
    42: {
      name: "Kosciusko",
      parent_id: "18085",
      x: 605.7,
      y: 164,
    },
    43: {
      name: "LaGrange",
      parent_id: "18087",
      x: 682.8,
      y: 76.7,
    },
    44: {
      name: "Lake",
      parent_id: "18089",
      x: 332.2,
      y: 126.2,
    },
    45: {
      name: "LaPorte",
      parent_id: "18091",
      x: 449.8,
      y: 90.4,
    },
    46: {
      name: "Lawrence",
      parent_id: "18093",
      x: 491.7,
      y: 738.3,
    },
    47: {
      name: "Madison",
      parent_id: "18095",
      x: 630,
      y: 429.6,
    },
    48: {
      name: "Marion",
      parent_id: "18097",
      x: 554.5,
      y: 518.9,
    },
    49: {
      name: "Marshall",
      parent_id: "18099",
      x: 532.3,
      y: 152.9,
    },
    50: {
      name: "Martin",
      parent_id: "18101",
      x: 434.8,
      y: 788.7,
    },
    51: {
      name: "Miami",
      parent_id: "18103",
      x: 569.6,
      y: 279.1,
    },
    52: {
      name: "Monroe",
      parent_id: "18105",
      x: 484.5,
      y: 654.3,
    },
    53: {
      name: "Montgomery",
      parent_id: "18107",
      x: 418.5,
      y: 452.9,
      size: 10,
    },
    54: {
      name: "Morgan",
      parent_id: "18109",
      x: 501.9,
      y: 591.4,
    },
    55: {
      name: "Newton",
      parent_id: "18111",
      x: 327.5,
      y: 258.5,
    },
    56: {
      name: "Noble",
      parent_id: "18113",
      x: 683.4,
      y: 136.2,
    },
    57: {
      name: "Ohio",
      parent_id: "18115",
      x: 769.7,
      y: 713.4,
      size: 10,
    },
    58: {
      name: "Orange",
      parent_id: "18117",
      x: 490.2,
      y: 807,
    },
    59: {
      name: "Owen",
      parent_id: "18119",
      x: 430.4,
      y: 636.5,
    },
    60: {
      name: "Parke",
      parent_id: "18121",
      x: 364.7,
      y: 527.1,
    },
    61: {
      name: "Perry",
      parent_id: "18123",
      x: 465.8,
      y: 906.6,
    },
    62: {
      name: "Pike",
      parent_id: "18125",
      x: 363.2,
      y: 834.4,
    },
    63: {
      name: "Porter",
      parent_id: "18127",
      x: 385.6,
      y: 119.5,
    },
    64: {
      name: "Posey",
      parent_id: "18129",
      x: 248.9,
      y: 923.7,
    },
    65: {
      name: "Pulaski",
      parent_id: "18131",
      x: 453.6,
      y: 220.8,
    },
    66: {
      name: "Putnam",
      parent_id: "18133",
      x: 426.6,
      y: 561.6,
    },
    67: {
      name: "Randolph",
      parent_id: "18135",
      x: 757.5,
      y: 430.7,
    },
    68: {
      name: "Ripley",
      parent_id: "18137",
      x: 710.5,
      y: 675.1,
    },
    69: {
      name: "Rush",
      parent_id: "18139",
      x: 675.7,
      y: 556.8,
    },
    70: {
      name: "St. Joseph",
      parent_id: "18141",
      x: 530.9,
      y: 82.1,
    },
    71: {
      name: "Scott",
      parent_id: "18143",
      x: 622,
      y: 773.3,
    },
    72: {
      name: "Shelby",
      parent_id: "18145",
      x: 617.1,
      y: 579.5,
    },
    73: {
      name: "Spencer",
      parent_id: "18147",
      x: 410,
      y: 917.3,
    },
    74: {
      name: "Starke",
      parent_id: "18149",
      x: 469.8,
      y: 163.4,
    },
    75: {
      name: "Steuben",
      parent_id: "18151",
      x: 752.4,
      y: 76.5,
    },
    76: {
      name: "Sullivan",
      parent_id: "18153",
      x: 324.2,
      y: 672.8,
    },
    77: {
      name: "Switzerland",
      parent_id: "18155",
      x: 755.8,
      y: 740.4,
      size: 10,
    },
    78: {
      name: "Tippecanoe",
      parent_id: "18157",
      x: 418.4,
      y: 376.1,
    },
    79: {
      name: "Tipton",
      parent_id: "18159",
      x: 570.2,
      y: 394.4,
    },
    80: {
      name: "Union",
      parent_id: "18161",
      x: 773.1,
      y: 555.5,
    },
    81: {
      name: "Vanderburgh",
      parent_id: "18163",
      x: 294,
      y: 913.8,
      size: 6,
    },
    82: {
      name: "Vermillion",
      parent_id: "18165",
      x: 322.9,
      y: 499.4,
      size: 8,
    },
    83: {
      name: "Vigo",
      parent_id: "18167",
      x: 329.6,
      y: 586.4,
    },
    84: {
      name: "Wabash",
      parent_id: "18169",
      x: 616.8,
      y: 266.8,
    },
    85: {
      name: "Warren",
      parent_id: "18171",
      x: 331.4,
      y: 381.8,
    },
    86: {
      name: "Warrick",
      parent_id: "18173",
      x: 344.2,
      y: 912.9,
    },
    87: {
      name: "Washington",
      parent_id: "18175",
      x: 560.4,
      y: 791,
      size: 10,
    },
    88: {
      name: "Wayne",
      parent_id: "18177",
      x: 759.9,
      y: 498.9,
    },
    89: {
      name: "Wells",
      parent_id: "18179",
      x: 723.2,
      y: 306.8,
    },
    90: {
      name: "White",
      parent_id: "18181",
      x: 411.9,
      y: 299.3,
    },
    91: {
      name: "Whitley",
      parent_id: "18183",
      x: 669,
      y: 199.7,
    },
  },
  legend: {
    entries: [],
  },
  regions: {},
};
