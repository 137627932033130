import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import { countryCodes } from "../config/country-codes";
import regexConfig from "../config/regex-config";
import { showToast, uploadFileOnServer } from "../helper-methods";
import { editTeamMember, fetchUserDetails } from "../http/http-calls";

const Settings = () => {
  /* eslint-disable */

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "",
    phone: "",
    location: "",
    image: {},
  });

  const [prevData, setPrevData] = useState({
    name: "",
    email: "",
    countryCode: "",
    phone: "",
    location: "",
    image: {},
  });

  const [isDirty, setIsDirty] = useState({
    name: false,
    email: false,
    countryCode: false,
    phone: false,
    location: false,
  });
  const [img, setImg] = useState();
  const [picUrl, setPicUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState("");

  useEffect(() => {
    _fetchProfileDetails();
  }, []);

  const _fetchProfileDetails = async () => {
    try {
      const res = await fetchUserDetails();
      console.log("profile", res);
      _setFormData(res?.user);
      setUserId(res?.user?._id);
    } catch (error) {
      console.log("error", error);
    }
  };

  const _setFormData = (data) => {
    let phoneNumber = data?.phone?.split("-");

    setFormData({
      name: data?.name?.full,
      countryCode: phoneNumber[0],
      phone: phoneNumber[1],
      email: data?.email,
      location: data?.location,
    });
    setPicUrl(data.profilePicture);

    setPrevData({
      name: data?.name?.full,
      countryCode: phoneNumber[0],
      phone: phoneNumber[1],
      email: data?.email,
      location: data?.location,
    });
  };

  const _addMemberHandler = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    const error = _validateForm(formData, newIsDirty);
    // console.log("errors => ", error);
    if (!error) {
      console.log("formdata", formData);

      let Username = formData.name.split(" ");
      let name = { first: Username[0], last: Username[1] };
      let finalPayload = { ...formData, name: name };

      if (img) {
        let payload = [{ uploadData: formData.image }];
        try {
          const imageRes = await uploadFileOnServer(payload);
          console.log("res", imageRes);
          setPicUrl(imageRes[0].url);
          if (
            formData.countryCode !== "" &&
            formData.phone !== "" &&
            (!formData.phone.length < 8 || !formData.phone.length > 12)
          ) {
            let newphone = formData.countryCode.concat("-", formData.phone);
            let data = { ...finalPayload, phone: newphone };
            delete data.countryCode;
            delete data.image;
            let newPayload = { ...data, profilePicture: imageRes[0].url };

            try {
              const res = await editTeamMember(userId, newPayload);
              console.log("createTeamWithPhoto>>", res);
              _fetchProfileDetails();
              showToast(
                res.message && res.message.length
                  ? res.message
                  : "User Created Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        if (
          formData.countryCode !== "" &&
          formData.phone !== "" &&
          (!formData.phone.length < 8 || !formData.phone.length > 12)
        ) {
          let newphone = formData.countryCode.concat("-", formData.phone);
          delete formData.image;
          let data = { ...finalPayload, phone: newphone };
          delete data.countryCode;
          try {
            const res = await editTeamMember(userId, data);
            console.log("createTeam>>", res);
            _fetchProfileDetails();

            showToast(
              res.message && res.message.length
                ? res.message
                : "User Created Successfully!",
              "success"
            );
          } catch (error) {
            console.log("error", error);
            showToast(
              error.reason && error.reason.length
                ? error.reason
                : "Server error. Try again after sometime.",
              "error"
            );
          }
        }
      }
    }
  };
  //onChange
  const _handelOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };

    if (field === "image") {
      newFormData[field] = event.target.files[0];
      setImg(URL.createObjectURL(event.target.files[0]));
    } else {
      newFormData[field] = event.target.value;
    }

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
    _validateForm(newFormData, newIsDirty);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "name" && dirties[each]) {
        if (!data.name.trim()) {
          newErrors.name = "*Required";
        } else if (
          data.name.trim().length < 2 ||
          data.name.trim().length > 25
        ) {
          newErrors.name =
            "*Name should be greater than 2 characters and less than 25 characters";
        } else if (
          data.name.trim().length &&
          !new RegExp(regexConfig.name).test(data.name)
        ) {
          newErrors.name =
            "*Numeric and Special Symbols not allowed. Please enter a valid name";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "phone" && dirties[each]) {
        if (!data.phone.trim()) {
          newErrors.phone = "*Required";
        } else if (
          data.phone.trim().length < 8 ||
          data.phone.trim().length > 12
        ) {
          newErrors.phone = "*Only Numbers allowed min 8 - max 12 characters";
        } else if (
          data.phone.trim().length &&
          !new RegExp(regexConfig.phone).test(data.phone)
        ) {
          newErrors.phone = "*Please enter a valid phone number";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "countryCode" && dirties[each]) {
        if (!data.countryCode.trim()) {
          newErrors.countryCode = "*Country Code Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "email" && dirties[each]) {
        if (!data.email.trim()) {
          newErrors.email = "*Required";
        } else if (data.email.trim().length > 40) {
          newErrors.email = "*Invalid Email";
        } else if (
          data.email.trim().length &&
          !new RegExp(regexConfig.email).test(data.email)
        ) {
          newErrors.email = "*Please enter a valid email ID";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "location" && dirties[each]) {
        if (!data.location.trim()) {
          newErrors.location = "*Location Required";
        } else if (
          data.location.trim().length < 2 ||
          data.location.trim().length > 20
        ) {
          newErrors.location = "*Location allowed min 2 - max 20 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  return (
    <div className="InnerWrapper">
      <div className="PgTitle">
        <h2 className="pg-heading">Profile</h2>
      </div>

      <Row className="m-0 justify-content-center ">
        <Col xl={7} lg={8} md={12} className="bg-white p-4">
          <Form>
            <FormGroup className="custom-file-upload">
              <Label className="text-center">
                <Input
                  type="file"
                  onChange={(event) => _handelOnChange("image", event)}
                />
                <div className="avatar-box mb-1">
                  <img src={img ? img : picUrl} alt="logo" />
                </div>
                Profile Pic
              </Label>
            </FormGroup>

            <Row>
              {/* == Company Name == */}
              <Col xl={4}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData?.name}
                    onChange={(event) => _handelOnChange("name", event)}
                  />
                </FormGroup>
                {errors.name ? (
                  <div className="form-error">{errors.name}</div>
                ) : null}
              </Col>
              {/* == Email == */}
              <Col xl={4}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    placeholder="Enter"
                    value={formData.email}
                    onChange={(event) => _handelOnChange("email", event)}
                    disabled
                  />
                  {errors.email ? (
                    <div className="form-error">{errors.email}</div>
                  ) : null}
                </FormGroup>
              </Col>
              {/* == Phone Number == */}
              <Col xl={4}>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <Input
                        type="select"
                        placeholder="Enter"
                        value={formData?.countryCode}
                        onChange={(event) =>
                          _handelOnChange("countryCode", event)
                        }
                      >
                        <option value="">select</option>
                        {countryCodes.map((each) => (
                          <option key={each.code} value={each.dial_code}>
                            {each.code} ({each.dial_code})
                          </option>
                        ))}
                      </Input>
                    </InputGroupAddon>
                    <Input
                      // type="number"
                      placeholder="Enter"
                      value={formData?.phone}
                      onChange={(event) => _handelOnChange("phone", event)}
                    />
                  </InputGroup>
                  {errors.phone || errors.countryCode ? (
                    <div className="form-error">
                      {errors.phone ? errors.phone : errors.countryCode}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              {/* == Address == */}
              <Col md="12">
                <FormGroup>
                  <Label>Location</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={formData.location}
                    onChange={(event) => _handelOnChange("location", event)}
                  />
                  {errors.location ? (
                    <div className="form-error">{errors.location}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            {/* <Row>
              <Col md="3">
                <FormGroup>
                  <Label>Country</Label>
                  <Input type="text" placeholder="Enter" />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>State</Label>
                  <Input type="text" placeholder="Enter" />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>City</Label>
                  <Input type="text" placeholder="Enter" />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>Zip</Label>
                  <Input type="text" placeholder="Enter" />
                </FormGroup>
              </Col>
            </Row> */}
            <div className="text-center">
              <Button
                color="primary"
                className="mx-auto mt-4"
                onClick={() => _addMemberHandler()}
                disabled={JSON.stringify(prevData) === JSON.stringify(formData)}
              >
                Save
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
