import cloneDeep from "clone-deep";
import { ToastsStore } from "react-toasts";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { history } from "../App";
import CONFIG from "../config";
import { getToken } from "../http/token-interceptor";
import S3BucketUploader from "../aws/s3-bucket-uploader";
import { PostManager } from "../aws/post-manager";
import { UploadQueueManager } from "../aws/upload-queue-manager";
import config from "../config";

export const logout = async (navRef, isDeviceLogoutRequired) => {
  localStorage.removeItem(config.TOKEN_NAME);

  if (navRef) {
    navRef.replace("/login");
  } else {
    history.replace("/login");
  }
};

export const decodeToken = (token) => {
  return jwt_decode(token);
};

export const deepClone = (data) => {
  return cloneDeep(data);
};

export const showToast = (message, type = "error", duration = 4000) => {
  ToastsStore[type](message, duration);
};

export const errorHandler = (error) => {
  console.log("error>>", error);
  showToast(
    error?.reason?.length || error?.message?.length
      ? error?.reason || error?.message
      : "Something went wrong, Try again later."
  );
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const getPostedDateValue = (postedDate) => {
  if (moment().isSame(postedDate, "year")) {
    return moment(postedDate).format("MMM DD");
  } else {
    return moment(postedDate).format("MMM DD, YYYY");
  }
};

export const debounce = (func, delay = 500) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export const sortedThreads = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t2[attr]) - new Date(t1[attr]);
  });
};

export const formatCurrencyValue = (data) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let currency = formatter.format(data);
  if (currency && currency.split(".")[1] === "00") {
    return currency.split(".")[0];
  }
  return currency; /* $2,500.00 */
};

export const capitalize = (s) => {
  if (!s || typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatDate = (date) => {
  if (!date) return "";

  if (moment().isSame(date, "year")) {
    return moment(date).format("MMM DD");
  } else {
    return moment(date).format("MMM DD, YYYY");
  }
};

export const formatDateAndTime = (date) => {
  if (!date) return "";

  if (moment().isSame(date, "year")) {
    return moment(date).format("MMM DD - hh:mm a");
  } else {
    return moment(date).format("MMM DD, YYYY - hh:mm a");
  }
};

export const openLinkInNewTab = (link) => {
  // Check if the link contains "https://"
  if (!link.includes("https://")) {
    // If not, add "https://" to the link
    link = "https://" + link;
  }

  // Open the link in a new tab
  window.open(link, "_blank");
};
export const getPhoneNumberFromBrackets = (number) => {
  let phone = "";
  if (number) {
    if (number.includes(")")) {
      phone = number.split(")")[1];
    } else {
      phone = number;
    }
  }
  return phone;
};

export const formatPhoneNumber = (phone) => {
  if (phone) {
    if (phone.includes(")")) {
      let phoneSplit = phone.split(")");
      return `${phoneSplit[0].slice(1)} ${phoneSplit[1]}`;
    } else {
      return phone;
    }
  } else {
    return "N/A";
  }
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    queryParamString = decodeURIComponent(queryParamString);
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const onUploadProgress = (evt, onProgressCallback) => {
  let uploadPercentage = parseInt((evt.loaded * 100) / evt.total) + "%";

  onProgressCallback(uploadPercentage);
};

export const onComplete = (error, success) => {
  console.log("error, success :", error, success);
};

// program to get the file extension
export const getFileExtension = (filename) => {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
};

export const uploadPhotoToCloudinary = (
  photo,
  type = "blob",
  fileName = null,
  fileType = "image",
  isLargeFile = false,
  onProgressCallback = () => {},
  isFakeCall = false
) => {
  return new Promise(async (resolve, reject) => {
    if (isFakeCall) {
      resolve("");
    } else {
      if (type === "blob") {
        fileName = Math.random().toString(36).substring(2);

        if (photo.name) {
          fileName = `${fileName}.${getFileExtension(photo.name)}`;
        } else {
          fileName = fileName + (fileType === "image" ? ".jpg" : ".pdf");
        }

        if (fileType === "image" || fileType === "pdf") {
          try {
            const token = await getToken();

            const config = await S3BucketUploader.getCreds(
              CONFIG.baseURL + "awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              photo,
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType
            );
            resolve(s3Response.Location);
          } catch (error) {
            reject(error);
          }
        }
      }
    }
  });
};

export const getAWSBucketName = (type) => {
  switch (type) {
    case "image":
      return CONFIG.AWS_IMAGE_BUCKET_NAME;
    default:
      return null;
  }
};

/**
 * uploadFiles is Object Array;
 * object key is;
 * - uploadData
 * - previewBlob
 * - type
 *
 * @param {Array} uploadFiles - file Object Array
 * @returns Array Object; object key is;
 * - title
 * - url
 * - docType
 */
// export const uploadFileOnServer = (uploadFiles) => {
//   return new Promise((resolve, reject) => {
//     const uploadedFiles = [];

//     console.log("uploadFiles", uploadFiles);

//     if (uploadFiles && uploadFiles.length) {
//       let postID = PostManager.addMediaFilesCount(uploadFiles.length);

//       PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
//         if (id.postID === postID) {
//           console.log("uploadedFiles :>> ", uploadedFiles);
//           await sleepTime(500);
//           resolve(uploadedFiles);
//           PostManager.deletePostID(postID);
//         } else {
//           return;
//         }
//       });

//       uploadFiles.forEach((uploadFile, index) => {
//         let mediaData = {
//           file: uploadFile.uploadData,
//           blobObject: uploadFile.previewBlob,
//         };
//         const uploadId = UploadQueueManager.addMediaToQueue(
//           mediaData,
//           uploadFile.type
//         );

//         // Listen for upload complete
//         UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
//           PostManager.onSingleMediaFileUploadCompleted(postID);
//           console.log("mediaResponse", mediaResponse, mediaResponse.fileUrl);
//           // Upload complete
//           // Get content id from backend
//           uploadedFiles.push({
//             title: uploadFile.uploadData.name,
//             url: mediaResponse.fileUrl,
//             docType:
//               uploadFile.type === "pdf"
//                 ? getFileExtension(uploadFile.uploadData.name)
//                 : uploadFile.type,
//           });
//         });
//       });
//     }
//   });
// };

export const uploadFileOnServer = (uploadFiles) => {
  return new Promise((resolve) => {
    console.log("receive uploadFiles>>", uploadFiles);

    const uploadedFiles = [];

    if (uploadFiles && uploadFiles.length) {
      let postID = PostManager.addMediaFilesCount(uploadFiles.length);

      PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
        if (id.postID === postID) {
          PostManager.deletePostID(postID);
          resolve(uploadedFiles);
        } else {
          return;
        }
      });

      uploadFiles.forEach((uploadFile) => {
        console.log(">>>", uploadFile);
        let mediaData = {};

        if (!uploadFile["type"]) {
          uploadFile["type"] = uploadFile["uploadData"]["type"].split("/")[0];
        }

        if (uploadFile["type"] === "video") {
          mediaData = {
            blobObject: uploadFile["uploadData"],
            blobURL: uploadFile["previewBlob"],
          };
        } else if (
          uploadFile["type"] === "image" ||
          uploadFile["type"] === "pdf" ||
          uploadFile["type"] === "application"
        ) {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: uploadFile["previewBlob"],
          };
        } else if (uploadFile["type"] === "audio") {
          mediaData = {
            file: uploadFile["uploadData"],
            blobObject: { blob: uploadFile["uploadData"] },
          };
        }

        const uploadId = UploadQueueManager.addMediaToQueue(
          mediaData,
          uploadFile.type
        );

        // Listen for upload complete
        UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
          PostManager.onSingleMediaFileUploadCompleted(postID);
          console.log("mediaResponse", mediaResponse, mediaResponse.fileUrl);
          // Upload complete
          // Get content id from backend
          uploadedFiles.push({
            name: uploadFile.uploadData.name,
            url: mediaResponse.fileUrl,
            contentType:
              uploadFile.type === "pdf"
                ? getFileExtension(uploadFile.uploadData.name)
                : uploadFile.type,
            size: uploadFile.uploadData.size,
            forKeyName: uploadFile.forKeyName,
          });
        });
      });
    }
  });
};

/**
 * this function accept time in T format "2020-12-03T13:41:17.672Z"
 * Will tell if the time is beyond 24 hours
 * and Will return boolean value
 *
 * @param {Date} time - 2020-12-03T13:41:17.672Z
 *
 * @return {Boolean} true // false
 */
export const isTimeMoreThan24h = (time) => {
  let isGreater = false;
  let hours = Math.abs(new Date() - new Date(time)) / 36e5;
  // The subtraction returns the difference between the two dates in milliseconds.
  // 36e5 is the scientific notation for 60*60*1000,
  // dividing by which converts the milliseconds difference into hours.
  if (hours > 24) {
    isGreater = true;
  }

  return isGreater;
};
