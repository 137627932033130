import React from "react";
import { memo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { deepClone } from "../../helper-methods";
import { countyNameToId } from "./indianaStates";
import {
  initialStateSpecificData,
  simplemaps_statemap_mapdata,
} from "./mapdata";

// const locations = {
//   0: {
//     name: "Four Seasons Country Club",
//     description: `<ul><li>Veterans Interested: 4</li><li>Searches Made: 5</li><li>Vendors: 6</li></ul>`,
//     lat: 41.4484712,
//     lng: -87.1673849,
//     display: "state",
//     pulse: "yes",
//     // type: "image",
//     // image_url:
//     //   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfHS7BhWxa_MSW3xWba2jpAQZSb-SKiL84hw&usqp=CAU",
//   },
//   1: {
//     name: "Humane Indiana Wildlife",
//     description: `<ul><li>Veterans Interested: 4</li><li>Searches Made: 5</li><li>Vendors: 6</li></ul>`,
//     lat: 41.4675496,
//     lng: -87.1329322,
//     display: "state",
//     pulse: "yes",
//   },
//   2: {
//     name: "Berne city",
//     description: `<ul><li>Veterans Interested: 4</li><li>Searches Made: 5</li><li>Vendors: 6</li></ul>`,
//     lat: 40.66,
//     lng: -84.96,
//     display: "state",
//     pulse: "yes",
//   },
//   3: {
//     name: "Canaan",
//     description: `<ul><li>Veterans Interested: 4</li><li>Searches Made: 5</li><li>Vendors: 6</li></ul>`,
//     lat: 38.87,
//     lng: -85.3,
//     display: "state",
//     pulse: "yes",
//   },
//   4: {
//     name: "Holland town",
//     description: `<ul><li>Veterans Interested: 4</li><li>Searches Made: 5</li><li>Vendors: 6</li></ul>`,
//     lat: 38.25,
//     lng: -87.04,
//     display: "state",
//     pulse: "yes",
//   },
// };

const IndianaCountyMap = ({ data = [] }) => {
  const [currentZoomLabel, setCurrentZoomLabel] = useState("Indiana");

  const _initializeMap = () => {
    try {
      if (!data?.length) return;

      const allLocationsData = [];

      const stateSpecificData = {};

      data.forEach((each, index) => {
        if (each.countyName) {
          if (each?._postalCode?.length) {
            allLocationsData.push(...each._postalCode);
          }

          if (!stateSpecificData[each.countyName]) {
            stateSpecificData[each.countyName] = {
              id: countyNameToId[each.countyName],
              countyName: each.countyName,
              numberOfSearchByCounty: 0,
              numberOfVendoresByCounty: 0,
              numberOfVetSearchByCounty: 0,
              total: 0,
            };
          }

          stateSpecificData[each.countyName].numberOfVetSearchByCounty +=
            each.numberOfVetSearchByCounty;
          stateSpecificData[each.countyName].numberOfSearchByCounty +=
            each.numberOfSearchByCounty;
          stateSpecificData[each.countyName].numberOfVendoresByCounty +=
            each.numberOfVendoresByCounty;
          stateSpecificData[each.countyName].total +=
            each.numberOfSearchByCounty +
            each.numberOfVendoresByCounty +
            each.numberOfVetSearchByCounty;
        }
      });

      const locations = {};

      allLocationsData.forEach((each, index) => {
        if (
          each.countyName &&
          each.coordinates?.latitude &&
          each.coordinates?.longitude
        ) {
          locations[index] = {
            name: each.name,
            description: `<ul><li>Veterans Interested: ${
              each.numberOfVetSearchByPostalCode || 0
            }</li><li>Searches Made: ${
              each.numberOfSearchByPostalCode || 0
            }</li><li>Vendors: ${
              each.numberOfVendoresByPostalCode || 0
            }</li></ul>`,
            lat: each.coordinates.latitude,
            lng: each.coordinates.longitude,
            display: "state",
            pulse: "yes",
          };
        }
      });

      simplemaps_statemap_mapdata.locations = locations;

      /**
       * 0-100 #27c77d
       * 101-500 #2549ff
       * 501-1000 #ffa611
       * 1000+ #f86c6b
       */
      const _getColor = (total) => {
        if (total > 1000) {
          return "#f86c6b";
        } else if (total > 500) {
          return "#ffa611";
        } else if (total > 100) {
          return "#2549ff";
        } else if (total >= 0) {
          return "#27c77d";
        } else {
          return "default";
        }
      };

      simplemaps_statemap_mapdata.state_specific = deepClone(
        initialStateSpecificData
      );

      Object.keys(stateSpecificData).forEach((key) => {
        const each = stateSpecificData[key];

        /**
         * 0-100 #27c77d
         * 101-500 #2549ff
         * 501-1000 #ffa611
         * 1000+ #f86c6b
         */
        simplemaps_statemap_mapdata.state_specific[each.id].color = _getColor(
          each.total
        );
        simplemaps_statemap_mapdata.state_specific[
          each.id
        ].description = `<ul><li>Veterans Interested: ${each.numberOfVetSearchByCounty}</li><li>Searches Made: ${each.numberOfSearchByCounty}</li><li>Vendors: ${each.numberOfVendoresByCounty}</li></ul>`;
      });

      window.simplemaps_statemap.mapdata = simplemaps_statemap_mapdata;

      window.simplemaps_statemap.load();

      window.simplemaps_statemap.hooks.zoomable_click_state = function (id) {
        setCurrentZoomLabel(
          simplemaps_statemap_mapdata.state_specific[id].name
        );
      };
      window.simplemaps_statemap.hooks.back = function () {
        setCurrentZoomLabel("Indiana");
      };
      // window.simplemaps_statemap.hooks.click_location = function (id) {
      //   window.simplemaps_statemap.location_zoom(id);
      //   setCurrentZoomLabel(simplemaps_statemap_mapdata.locations[id].name);
      // };
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    _initializeMap();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data?.length) return <></>;

  return (
    <>
      <div className="mapWrap">
        <div className="mapContainer">
          <h2 className="maptitle">{currentZoomLabel}</h2>
          <div id="map"></div>
        </div>

        <div className="mapInfo">
          <ul>
            <li>
              <span className="green" />
              Poor 0 - 100
            </li>
            <li>
              <span className="blue" />
              Moderate 101 - 500
            </li>
            <li>
              <span className="orange" />
              Sufficient 501 - 1000
            </li>
            <li>
              <span className="red" />
              Surplus 1000+
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default memo(IndianaCountyMap);
