import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Table,
} from "reactstrap";
import AddProviderModal from "../components/Modal/AddProviderModal";
import DeleteAlertModal from "../components/Modal/DeleteAlertModal";
import ProviderView from "../components/Modal/ProviderView";
import {
  deleteOneProvider,
  fetchProvider,
  providerStatus,
  fetchAllServices,
  providerRequest,
} from "../http/http-calls";
import { deepClone, openLinkInNewTab, showToast } from "../helper-methods";
import config from "../config/index";
import PaginatedItems from "../components/PaginatedItems";
import ProviderNotesModal from "../components/Modal/ProviderNotesModal";
import { useSelector } from "react-redux";
import SvgIcons from "../components/SvgIcons";

const ProviderList = () => {
  /* eslint-disable */
  const userData = useSelector((state) => state?.userCredential?.user);

  // provider
  const [provider, SetProvider] = useState(false);
  // Add Provider
  const [Addprovider, SetAddprovider] = useState(false);
  const [notesModal, SetNotesModal] = useState({
    isOpen: false,
    id: "",
  });
  const [delModeOpen, setDelModeOpen] = useState(false);
  const [deleteId, setDelId] = useState("");
  const [editDetails, setEditDetails] = useState(null);
  const [payload, setPayload] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [providers, SetProviders] = useState([]);
  const [viewCountyService, setViewCountyService] = useState(null);
  const [services, setServices] = useState([]);

  const [count, setCount] = useState("");
  const [itemsPerPages, setItemsPerPages] = useState("");
  const [flag, setFlag] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  // provider
  const _ProviderToggle = (isOpen = false) => {
    SetProvider(isOpen);
    setViewCountyService(null);
  };
  // Add Provider
  const _AddProviderToggle = (isOpen = false) => {
    SetAddprovider(isOpen);
    setEditDetails(null);
  };

  const _NotesModalToggle = (isOpen = false, id = "") => {
    SetNotesModal({
      isOpen: isOpen,
      id: id,
    });
  };

  // const __fetchAllCounty = async () => {
  //   try {
  //     const res = await fetchCounty()
  //     console.log("allCounties", res);
  //     setCounties(res.county)
  //   } catch (error) {
  //     console.log("error", error);
  //     showToast(
  //       error.reason && error.reason.length
  //         ? error.reason
  //         : "Server error. Try again after sometime.",
  //       "error"
  //     );
  //   }
  // }

  useEffect(() => {
    _fetchAllProviders();
    _fetchAllServices();
    // __fetchAllCounty()
  }, []);

  const _fetchAllProviders = async (newPayload) => {
    try {
      const res = await fetchProvider(newPayload ? newPayload : payload);
      console.log("providers", res);
      SetProviders(res?.providers);
      setCount(res?.providersCount);
      setItemsPerPages(res?.providers?.length);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  const _fetchAllServices = async () => {
    try {
      const res = await fetchAllServices();
      console.log("allservices", res);
      setServices(res?.services);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  //edit
  const _editHandler = (details) => {
    _AddProviderToggle(true);
    setEditDetails(details);
  };

  //service shoe
  const _servicesHandler = (details) => {
    _ProviderToggle(true);
    setViewCountyService(details);
  };
  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 500);
  //   };
  // };

  //filter and search
  const _statusHandler = async (field, event) => {
    event.persist();
    const newSearchData = payload;
    newSearchData[field] = event.target.value;

    if (event.target.value?.length) {
      setPayload[field] = event.target.value;
      setPayload({ ...payload, pageNumber: 1 });
    } else {
      delete newSearchData[field];
      _fetchAllProviders();
    }

    // _checkHandler(newSearchData);
  };

  //debouncing
  useEffect(() => {
    if (
      payload.hasOwnProperty("searchText") ||
      payload.hasOwnProperty("county") ||
      payload.hasOwnProperty("service") ||
      payload.hasOwnProperty("status")
    ) {
      const _checkHandler = setTimeout(async () => {
        try {
          const res = await fetchProvider(payload);
          console.log("searchFilter>>", res);
          SetProviders(res?.providers);
          setCount(res?.providersCount);
          setItemsPerPages(res?.providers?.length);
        } catch (error) {
          console.log(error);
          showToast(
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        }
      }, 500);
      return () => clearTimeout(_checkHandler);
    }
  }, [payload]);
  //status change
  const _changeStatus = async (event, id, index) => {
    setFlag(true);

    console.log("id", id);
    let oldReports = JSON.parse(JSON.stringify(providers));

    const value = event.target.value;
    providers[index].isActive = value;
    SetProviders([...providers]);

    try {
      const res = await providerStatus(id, { status: value });
      console.log("resForUpdatingStatus>>", res);
      showToast(
        res.message && res.message.length
          ? res.message
          : "User Status Successfully Updated!",
        "success"
      );
      // _fetchAllProviders();
    } catch (error) {
      SetProviders(oldReports);
      console.log(error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
    setFlag(false);
  };

  //delete

  const _deleteToggle = (delModeOpen = false, id) => {
    setDelModeOpen(delModeOpen);
    setDelId(id);
  };

  const _removeUser = async (memberId) => {
    // console.log(memberId);
    _deleteToggle(true, memberId);
  };

  //delete user handler
  const _deleteHandler = async () => {
    try {
      const res = await deleteOneProvider(deleteId);
      console.log("deleteUser", res);
      _fetchAllProviders();
      showToast(
        res.message && res.message.length
          ? res.message
          : "User Deleted Successfully!",
        "success"
      );
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  const _clearAllHandler = () => {
    document.getElementById("searchText").value = "";
    document.getElementById("county").value = "";
    document.getElementById("service").value = "";
    document.getElementById("status").value = "";
    setPayload({
      pageNumber: 1,
      pageSize: 10,
    });
    _fetchAllProviders({
      pageNumber: 1,
      pageSize: 10,
    });
  };

  //pagination
  const _handlePageClick = (pageNumber) => {
    // console.log(pageNumber);
    let newPageConfig = deepClone(payload);
    newPageConfig["pageNumber"] = pageNumber + 1;
    setPayload(newPageConfig);
    _fetchAllProviders(newPageConfig);
  };

  const _requestSubmitHandler = async (id, value) => {
    try {
      const res = await providerRequest(id, { status: value });
      console.log("res>>>>>", res);
      showToast(res?.message, "success");
      _fetchAllProviders();
    } catch (error) {
      console.log("error", error?.reason);
      showToast(error.reason, "error");
    }
  };
  return (
    <>
      <div className="InnerWrapper">
        <div className="PgTitle">
          <h2 className="pg-heading">Provider List</h2>
          <div className="right">
            {userData?.provider?.add ? (
              <Button
                color="primary"
                onClick={() => {
                  _AddProviderToggle(true);
                }}
              >
                <i className="fa fa-plus mr-2"></i>
                New
              </Button>
            ) : null}
            <Button
              color="link"
              className="filterIcon"
              onClick={() => setShowFilter(!showFilter)}
            >
              <SvgIcons type={"filter"} />
            </Button>
          </div>
        </div>

        <div
          className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
        >
          <h3>Filters</h3>

          <div className="filterWarp">
            <FormGroup className="searchBox">
              <Label>Search</Label>
              <InputGroup>
                <Input
                  data-test="search-input-filter"
                  type="text"
                  name="search"
                  placeholder="Search"
                  autoComplete="off"
                  id="searchText"
                  onChange={(event) => _statusHandler("searchText", event)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText data-test="search-button">
                    <i className="icon-magnifier"></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>County</Label>
              <Input
                type="select"
                name="supervisor"
                id="county"
                onChange={(event) => _statusHandler("county", event)}
              >
                <option value="">All</option>
                {config?.county?.map((each, index) => (
                  <option key={index} value={each}>
                    {each}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Resources</Label>
              <Input
                type="select"
                name="position"
                id="service"
                onChange={(event) => _statusHandler("service", event)}
              >
                <option value="">All</option>
                {services?.map((each, index) => (
                  <option key={index} value={each?._id}>
                    {each?.serviceName}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Input
                type="select"
                name="supervisor"
                id="status"
                onChange={(event) => _statusHandler("status", event)}
              >
                <option value="">All</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Input>
            </FormGroup>
            <Button
              color="danger"
              className="reset h-auto"
              outline
              onClick={() => _clearAllHandler()}
            >
              <i className="fa fa-eraser"></i>
            </Button>
          </div>

          <div className="right"></div>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Company</th>
              <th>Owner</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Web Site</th>
              <th>Resources</th>
              <th>Notes</th>
              <th>Status</th>
              <th>Disscount</th>
              <th>Request</th>
              {userData?.provider?.edit ? (
                <th style={{ width: 140, textAlign: "right" }}>Actions</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {providers?.length ? (
              providers?.map((data, index) => (
                <tr key={index}>
                  <td>
                    <div style={{ width: "150px" }}>
                      {editDetails
                        ? FormData?.providerName
                        : data?.providerName}{" "}
                    </div>
                  </td>
                  <td>
                    <div style={{ width: "150px" }}>
                      {data?.contactName?.last
                        ? data?.contactName?.first.concat(
                            " ",
                            data?.contactName?.last
                          )
                        : data?.contactName?.first}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div style={{ width: 150 }}>{data?.providerEmail}</div>
                  </td>
                  <td className="cursorPointer">
                    <div className="link text-nowrap">
                      {data?.providerPhone}
                    </div>
                  </td>
                  <td className="cursorPointer">
                    {" "}
                    <div
                      style={{ width: 150 }}
                      onClick={() => openLinkInNewTab(data?.providerWebsiteUrl)}
                    >
                      {data?.providerWebsiteUrl}{" "}
                    </div>
                  </td>
                  <td>
                    {data?._service?.length}
                    <Button
                      color="link"
                      style={{ width: 50 }}
                      onClick={() => _servicesHandler(data)}
                    >
                      <i style={{ fontSize: 17 }} className="fa fa-eye"></i>
                    </Button>
                  </td>
                  <td>
                    <Button
                      color="link"
                      onClick={() => {
                        _NotesModalToggle(true, data?._id);
                      }}
                      style={{ width: 50 }}
                    >
                      <i className="fa fa-file mr-2"></i>
                      <span>{data?._note?.length}</span>
                    </Button>
                  </td>
                  <td>
                    <Input
                      type="select"
                      className="mr-2"
                      value={data?.isActive}
                      onChange={(event) =>
                        _changeStatus(event, data._id, index)
                      }
                      disabled={flag}
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </Input>
                  </td>
                  <td>{data?.isDiscount ? "Yes" : "No"}</td>
                  <td>
                    <div style={{ width: 100 }}>
                      {data?.status === "pending" &&
                      userData?.provider?.acceptReject ? (
                        <>
                          <Button
                            className="text-success"
                            color="link"
                            onClick={() =>
                              _requestSubmitHandler(data?._id, "accepted")
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            className="remove"
                            color="link"
                            onClick={() =>
                              _requestSubmitHandler(data?._id, "rejected")
                            }
                          >
                            Reject
                          </Button>
                        </>
                      ) : (
                        <Button
                          color="link"
                          className={
                            data?.status === "accepted"
                              ? "text-success"
                              : data?.status === "rejected"
                              ? "text-danger"
                              : ""
                          }
                        >
                          {data?.status?.charAt(0)?.toUpperCase() +
                            data?.status?.slice(1)}
                        </Button>
                      )}
                    </div>
                  </td>

                  {userData?.provider?.edit ? (
                    <td>
                      <div className="action">
                        <Button
                          color="link"
                          className="btn-edit"
                          onClick={() => _editHandler(data)}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>
                        <Button
                          color="link"
                          className="btn-delete"
                          onClick={() => _removeUser(data?._id)}
                        >
                          <i className="fa fa-trash-o"></i>
                        </Button>
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11}>
                  <div className="noData">
                    <img
                      src={require("../assets/img/noData.svg")}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <PaginatedItems
          itemsPerPages={itemsPerPages}
          totalCount={count}
          selectedPage={payload.pageNumber}
          itemsPerPage={payload.pageSize}
          onPageChange={_handlePageClick}
        />
      </div>

      {/* provider view */}
      <ProviderView
        viewCountyService={viewCountyService}
        isOpen={provider}
        toggle={() => _ProviderToggle()}
      />

      {/* provider notes */}
      {notesModal?.isOpen && notesModal?.id !== "" && (
        <ProviderNotesModal
          isOpen={notesModal?.isOpen}
          toggle={() => _NotesModalToggle()}
          id={notesModal?.id}
          fetchAllProvider={_fetchAllProviders}
        />
      )}
      {/*Add provider  */}
      {Addprovider && (
        <AddProviderModal
          isOpen={Addprovider}
          toggle={() => _AddProviderToggle()}
          fetchAllProvider={_fetchAllProviders}
          isEdit={editDetails === null ? false : true}
          editDetails={editDetails}
          services={services}
        />
      )}
      <DeleteAlertModal
        delModeOpen={delModeOpen}
        deleteProvider={() => _deleteHandler()}
        toggle={() => _deleteToggle()}
      />
    </>
  );
};

export default ProviderList;
