import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  CustomInput,
  ModalFooter,
  // Col,
  InputGroup,
  InputGroupAddon,
  // InputGroupText,
} from "reactstrap";
import { countryCodes } from "../../config/country-codes";
import regexConfig from "../../config/regex-config";
import { createTeam, editTeamMember } from "../../http/http-calls";
import { showToast, uploadFileOnServer } from "../../helper-methods";
import { countyList } from "../../config/countyStructureConfig";
import AccordioanWrap from "../AccordioanWrap";

const CreateTeam = ({ isOpen, toggle, isEdit, editDetails, fetchAllUsers }) => {
  const _closeModal = () => {
    toggle();
    setErrors({});
    setFormData({
      name: "",
      email: "",
      countryCode: "+1",
      phone: "",
      location: "",
      permissions: [],
      image: {},
      resource: {
        view: false,
        add: false,
        edit: false,
      },
      team: {
        view: false,
        add: false,
        edit: false,
      },
      provider: {
        view: false,
        add: false,
        edit: false,
        acceptReject: false,
      },
      isAllCounty: false,
      county: [],
      allChecked: false,
    });

    setIsDirty({
      name: false,
      email: false,
      countryCode: false,
      phone: false,
      location: false,
    });
    setImg();
    setLoading(false);
  };

  useEffect(() => {
    if (editDetails !== null) {
      let phoneNumber = editDetails.phone.split("-");

      setFormData({
        name: editDetails?.name?.full,
        email: editDetails.email,
        countryCode: phoneNumber[0],
        phone: phoneNumber[1],
        location: editDetails?.location,
        permissions: editDetails?.permissions,
        resource: {
          view: editDetails?.resource?.view,
          add: editDetails?.resource?.add,
          edit: editDetails?.resource?.edit,
        },
        team: {
          view: editDetails?.team?.view,
          add: editDetails?.team?.add,
          edit: editDetails?.team?.edit,
        },
        provider: {
          view: editDetails?.provider?.view,
          add: editDetails?.provider?.add,
          edit: editDetails?.provider?.edit,
          acceptReject: editDetails?.provider?.acceptReject,
        },
        isAllCounty: editDetails?.isAllCounty,
        county: editDetails?.county,
        allChecked:
          isAllTrue(editDetails.resource) &&
          isAllTrue(editDetails.team) &&
          isAllTrue(editDetails.provider) &&
          editDetails.isAllCounty
            ? true
            : false,
      });
      setPicUrl(editDetails.profilePicture);

      setPrevData({
        name: editDetails?.name?.full,
        email: editDetails.email,
        countryCode: phoneNumber[0],
        phone: phoneNumber[1],
        location: editDetails?.location,
        permissions: editDetails?.permissions,
        resource: {
          view: editDetails?.resource?.view,
          add: editDetails?.resource?.add,
          edit: editDetails?.resource?.edit,
        },
        team: {
          view: editDetails?.team?.view,
          add: editDetails?.team?.add,
          edit: editDetails?.team?.edit,
        },
        provider: {
          view: editDetails?.provider?.view,
          add: editDetails?.provider?.add,
          edit: editDetails?.provider?.edit,
          acceptReject: editDetails?.provider?.acceptReject,
        },
        isAllCounty: editDetails?.isAllCounty,
        county: editDetails?.county,
        allChecked:
          isAllTrue(editDetails.resource) &&
          isAllTrue(editDetails.team) &&
          isAllTrue(editDetails.provider) &&
          editDetails.isAllCounty
            ? true
            : false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDetails]);

  //state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "+1",
    phone: "",
    location: "",
    image: {},
    resource: {
      view: false,
      add: false,
      edit: false,
    },
    team: {
      view: false,
      add: false,
      edit: false,
    },
    provider: {
      view: false,
      add: false,
      edit: false,
      acceptReject: false,
    },
    isAllCounty: false,
    county: [],
    allChecked: false,
  });

  const [prevData, setPrevData] = useState({
    name: "",
    email: "",
    countryCode: "",
    phone: "",
    location: "",
    permissions: [],
    image: {},
    resource: {
      view: false,
      add: false,
      edit: false,
    },
    team: {
      view: false,
      add: false,
      edit: false,
    },
    provider: {
      view: false,
      add: false,
      edit: false,
      acceptReject: false,
    },
    isAllCounty: false,
    county: [],
    allChecked: false,
  });

  const isAllTrue = (obj) => {
    return Object.values(obj).every((value) => {
      if (typeof value === "object") {
        return isAllTrue(value);
      }
      return value === true;
    });
  };

  const [isDirty, setIsDirty] = useState({
    name: false,
    email: false,
    countryCode: false,
    phone: false,
    location: false,
  });

  const [errors, setErrors] = useState({});
  const [img, setImg] = useState();
  const [picUrl, setPicUrl] = useState("");
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const permissionArray = ["veterans", "services", "providerList"];

  const _addMemberHandler = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    const error = _validateForm(formData, newIsDirty);
    // console.log("errors => ", error);
    if (!error) {
      // console.log("no error");
      setFlag(true);
      setLoading({ loading: true });

      let Username = formData.name.split(" ");
      let name = { first: Username[0], last: Username[1] };
      let finalPayload = { ...formData, name: name };

      if (editDetails) {
        if (
          formData.countryCode !== "" &&
          formData.phone !== "" &&
          (!formData.phone.length < 8 || !formData.phone.length > 12)
        ) {
          if (formData?.image?.type) {
            let newphone = formData.countryCode.concat("-", formData.phone);
            let data = { ...finalPayload, phone: newphone };
            delete data.countryCode;
            delete data.image;

            try {
              const imageRes = await uploadFileOnServer([
                { uploadData: formData.image },
              ]);

              try {
                const res = await editTeamMember(editDetails?._id, {
                  data,
                  profilePicture: imageRes[0].url,
                });
                console.log("createTeam>>", res);
                fetchAllUsers();

                showToast(
                  res.message && res.message.length
                    ? res.message
                    : "User Created Successfully!",
                  "success"
                );
              } catch (error) {
                console.log("error", error);
                showToast(
                  error.reason && error.reason.length
                    ? error.reason
                    : "Server error. Try again after sometime.",
                  "error"
                );
              }
            } catch (error) {
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          } else {
            let newphone = formData.countryCode.concat("-", formData.phone);
            // delete formData.image;
            let data = { ...finalPayload, phone: newphone };
            delete data.countryCode;
            delete data.image;
            try {
              const res = await editTeamMember(editDetails?._id, data);
              console.log("createTeam>>", res);
              fetchAllUsers();

              showToast(
                res.message && res.message.length
                  ? res.message
                  : "User Created Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          }
        }
      } else {
        if (
          formData.countryCode !== "" &&
          formData.phone !== "" &&
          (!formData.phone.length < 8 || !formData.phone.length > 12)
        ) {
          let newphone = formData.countryCode.concat("-", formData.phone);
          // delete formData.image;
          let data = { ...finalPayload, phone: newphone };
          delete data.countryCode;
          if (formData?.image?.type) {
            try {
              const imageRes = await uploadFileOnServer([
                { uploadData: formData.image },
              ]);
              delete data.image;
              try {
                const res = await createTeam({
                  ...data,
                  profilePicture: imageRes[0].url,
                });
                console.log("createTeam>>", res);
                fetchAllUsers();

                showToast(
                  res.message && res.message.length
                    ? res.message
                    : "User Created Successfully!",
                  "success"
                );
              } catch (error) {
                console.log("error", error);
                showToast(
                  error.reason && error.reason.length
                    ? error.reason
                    : "Server error. Try again after sometime.",
                  "error"
                );
              }
            } catch (error) {
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          } else {
            delete data.image;
            try {
              const res = await createTeam(data);
              console.log("createTeam>>", res);
              fetchAllUsers();

              showToast(
                res.message && res.message.length
                  ? res.message
                  : "User Created Successfully!",
                "success"
              );
            } catch (error) {
              console.log("error", error);
              showToast(
                error.reason && error.reason.length
                  ? error.reason
                  : "Server error. Try again after sometime.",
                "error"
              );
            }
          }
        }
      }

      setLoading({ loading: false });
      setFlag(false);
      _closeModal();
    }
  };

  //onChange
  const _handelOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };

    if (field === "image") {
      newFormData[field] = event.target.files[0];
      setImg(URL.createObjectURL(event.target.files[0]));
    } else if (field === "permissions") {
      const { checked, name } = event.target;
      // console.log("permissionChange", event.target.value, checked);

      if (name === "allSelect") {
        if (checked === true) {
          let tempUser = permissionArray.map((each) => {
            return each;
          });
          // console.log("all", tempUser);
          newFormData[field] = tempUser;
        } else {
          let test = permissionArray.filter((each) => null === each);
          // console.log("test>>", test);
          newFormData[field] = test;
          // console.log("bye ");
        }
      } else {
        let newPermissions = [...formData.permissions];
        let finalState = [];
        if (checked) {
          newPermissions.push(event.target.value);
          finalState = [...newPermissions];
          // console.log(finalState);
        } else {
          let test = newPermissions.filter(
            (each) => event.target.value !== each
          );
          // console.log("testing", test);
          finalState = [...test];
        }
        newFormData[field] = finalState;
        // console.log(">>>>", finalState);
      }
    } else {
      newFormData[field] = event.target.value;
    }

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
    _validateForm(newFormData, newIsDirty);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "name" && dirties[each]) {
        if (!data.name.trim()) {
          newErrors.name = "*Required";
        } else if (data.name.trim().length < 2 || data.name.length > 25) {
          newErrors.name =
            "*Name should be greater than 2 characters and less than 25 characters";
        } else if (
          data.name.trim().length &&
          !new RegExp(regexConfig.name).test(data.name)
        ) {
          newErrors.name =
            "*Numeric and Special Symbols not allowed. Please enter a valid name";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "phone" && dirties[each]) {
        if (!data.phone.trim()) {
          newErrors.phone = "*Required";
        } else if (
          data.phone.trim().length < 8 ||
          data.phone.trim().length > 12
        ) {
          newErrors.phone = "*Only Numbers allowed min 8 - max 12 numbers";
        } else if (
          data.phone.trim().length &&
          !new RegExp(regexConfig.phone).test(data.phone)
        ) {
          newErrors.phone = "*Please enter a valid phone number";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "countryCode" && dirties[each]) {
        if (!data.countryCode.trim()) {
          newErrors.countryCode = "*Country Code Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "email" && dirties[each]) {
        if (!data.email.trim()) {
          newErrors.email = "*Required";
        } else if (data.email.trim().length > 40) {
          newErrors.email = "*Invalid Email";
        } else if (
          data.email.trim().length &&
          !new RegExp(regexConfig.email).test(data.email)
        ) {
          newErrors.email = "*Please enter a valid email ID";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "location" && dirties[each]) {
        if (!data.location.trim()) {
          newErrors.location = "*Required";
        } else if (
          data.location.trim().length < 2 ||
          data.location.trim().length > 50
        ) {
          newErrors.location = "*Location allowed min 2 - max 50 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const handleCountryListChange = (newValue, actionMeta) => {
    const newFormData = { ...formData };

    const data = newValue?.map((item) => {
      return item.value;
    });
    newFormData["county"] = data;
    setFormData(newFormData);
  };

  const _checkBoxHandler = (field, key, event) => {
    const newFormData = { ...formData };
    if (field === "allChecked") {
      newFormData["resource"] = {
        view: event.target.checked ? true : false,
        add: event.target.checked ? true : false,
        edit: event.target.checked ? true : false,
      };
      newFormData["team"] = {
        view: event.target.checked ? true : false,
        add: event.target.checked ? true : false,
        edit: event.target.checked ? true : false,
      };
      newFormData["provider"] = {
        view: event.target.checked ? true : false,
        add: event.target.checked ? true : false,
        edit: event.target.checked ? true : false,
        acceptReject: event.target.checked ? true : false,
      };
      newFormData["isAllCounty"] = event.target.checked ? true : false;
      newFormData["county"] = [];
      newFormData["allChecked"] = event.target.checked ? true : false;
    } else {
      if (key === "All") {
        newFormData[field] = event.target.checked;
      } else {
        newFormData[field][key] = event.target.checked;
        if (key === "view" && !event.target.checked) {
          newFormData[field]["add"] = false;
          newFormData[field]["edit"] = false;
          if (field === "provider") {
            newFormData[field]["acceptReject"] = false;
          }
        }
      }
    }
    setFormData(newFormData);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        size="lg"
        centered
        scrollable
      >
        <ModalHeader>{isEdit ? "Edit" : "Create New"}</ModalHeader>
        <ModalBody>
          <div className="uploadFile text-center">
            <Label>
              <Input
                type="file"
                hidden
                accept="image/png, image/jpeg, image/jpg"
                onChange={(event) => _handelOnChange("image", event)}
              />
              {editDetails?.profilePicture && !img ? (
                <img
                  src={picUrl ? picUrl : img}
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src={img ? img : require("../../assets/img/default_user_icon.svg")}
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Label>
            <div className="editIcon">
              <i className="fa fa-pencil"></i>
            </div>
          </div>

          <FormGroup>
            <Label>Name</Label>
            <Input
              placeholder="Enter"
              value={formData?.name}
              onChange={(event) => _handelOnChange("name", event)}
            />
            {errors.name ? (
              <div className="form-error">{errors.name}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Email Address</Label>
            <Input
              type="email"
              placeholder="Enter"
              value={formData.email}
              disabled={editDetails}
              onChange={(event) => _handelOnChange("email", event)}
            />
            {errors.email ? (
              <div className="form-error">{errors.email}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Phone Number</Label>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Input
                  type="select"
                  placeholder="Enter"
                  value={formData?.countryCode}
                  onChange={(event) => _handelOnChange("countryCode", event)}
                >
                  {/* <option value="">select</option> */}
                  {countryCodes.map((each) => (
                    <option key={each.code} value={each.dial_code}>
                      {each.code} ({each.dial_code})
                    </option>
                  ))}
                </Input>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Enter"
                value={formData?.phone}
                onChange={(event) => _handelOnChange("phone", event)}
              />
            </InputGroup>

            {errors.phone || errors.countryCode ? (
              <div className="form-error">
                {errors.phone ? errors.phone : errors.countryCode}
              </div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Location</Label>
            <Input
              type="text"
              placeholder="Enter"
              value={formData?.location}
              onChange={(event) => _handelOnChange("location", event)}
            />
            {errors.location ? (
              <div className="form-error">{errors.location}</div>
            ) : null}
          </FormGroup>

          <h2 className="role_permission">
            Permissions
            <CustomInput
              type="checkbox"
              id="exampleCustomCheckbox"
              label="Select All"
              name="allSelect"
              checked={
                isAllTrue(formData.resource) &&
                isAllTrue(formData.team) &&
                isAllTrue(formData.provider) &&
                formData.isAllCounty
              }
              onClick={(event) => _checkBoxHandler("allChecked", "", event)}
            />
          </h2>

          <AccordioanWrap
            formData={formData}
            _checkBoxHandler={_checkBoxHandler}
            handleCountryListChange={handleCountryListChange}
            countyList={countyList}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={() => _addMemberHandler()}
            disabled={
              isEdit
                ? JSON.stringify(prevData) === JSON.stringify(formData) ||
                  flag === true
                : flag === true
            }
          >
            {loading ? (
              <i className="fa fa-spinner text-white mr-1 fa-spin" />
            ) : null}
            {editDetails ? "Update" : "Send"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateTeam;
