import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  // AppNavbarBrand,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
import config from "../../config/index";
import { deepClone, logout } from "../../helper-methods/index";

import ProtectedRoute from "../../components/protected-route";
import Home from "../../pages/home";
import Settings from "../../pages/settings";
import Services from "../../pages/Services";
import Team from "../../pages/Team";
import MessageTemplates from "../../pages/MessageTemplates";
import MediaQueueViewer from "../MediaQueueViewer";
import ProviderList from "../../pages/ProviderList";
import Notification from "../../pages/Notification";
import { connect } from "react-redux";

const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  _signOut(e = null) {
    if (e) e.preventDefault();
    logout();
  }

  _showHeadersName = () => {
    let headersData = deepClone(config?.leftMenu);
    if (!this.props?.userData?.provider?.view) {
      delete headersData.items[3];
    }
    if (!this.props?.userData?.team?.view) {
      delete headersData.items[2];
    }
    if (!this.props?.userData?.resource?.view) {
      delete headersData.items[1];
    }

    return headersData;
  };

  render() {
    return (
      <div className="app">
        <div className="app-body">
          <AppSidebar fixed display="xs">
            <Suspense>
              {this.props?.userData ? (
                <AppSidebarNav
                  navConfig={this?._showHeadersName()}
                  {...this.props}
                  router={router}
                  brand={{
                    width: "50px",
                    height: "50px",
                    src: "https://png.pngtree.com/png-clipart/20230330/original/pngtree-modern-demo-logo-vector-file-png-image_9012000.png",
                  }}
                ></AppSidebarNav>
              ) : null}
            </Suspense>
          </AppSidebar>
          <main className="main">
            <>
              <AppHeader fixed>
                <Suspense fallback={this.loading()}>
                  <DefaultHeader
                    onLogout={(e) => this._signOut(e)}
                    {...this.props}
                  />
                </Suspense>
              </AppHeader>
              <Switch>
                <ProtectedRoute
                  path={`/home`}
                  component={Home}
                  redirectRoute={"/login"}
                />

                {this.props?.userData?.resource?.view === true ? (
                  <ProtectedRoute
                    path={`/services`}
                    component={Services}
                    redirectRoute={"/login"}
                  />
                ) : null}
                {this.props?.userData?.team?.view === true ? (
                  <ProtectedRoute
                    path={`/team`}
                    component={Team}
                    redirectRoute={"/login"}
                  />
                ) : null}
                <ProtectedRoute
                  path={`/messagetemplates`}
                  component={MessageTemplates}
                  redirectRoute={"/login"}
                />

                <ProtectedRoute
                  path={`/settings`}
                  component={Settings}
                  redirectRoute={"/login"}
                />
                {this.props?.userData?.provider?.view === true ? (
                  <ProtectedRoute
                    path={`/provide-list`}
                    component={ProviderList}
                    redirectRoute={"/login"}
                  />
                ) : null}
                <ProtectedRoute
                  path={`/notification`}
                  component={Notification}
                  redirectRoute={"/login"}
                />

                {/* <Redirect from="/" to="/dashboard" /> */}
                <Route path="/" render={() => <Redirect to="/home" />} />
                {/* <Redirect from="/" exact to={localStorage[config.TOKEN_NAME] ? '/home' : '/login'} /> */}
              </Switch>
              {/* </Suspense> */}
              <MediaQueueViewer />
            </>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userCredential.user,
  };
};

export default connect(mapStateToProps)(DefaultLayout);
