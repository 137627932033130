import React, { useState, useEffect, useCallback } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Tooltip,
  InputGroup,
  InputGroupAddon,
  CustomInput,
} from "reactstrap";
// import Select from "react-select";
// import makeAnimated from 'react-select/animated';
import regexConfig from "../../config/regex-config";
import { createProvider, duplicate, editProvider } from "../../http/http-calls";
import { debounce, showToast } from "../../helper-methods";
import config from "../../config/index";
import { countryCodes } from "../../config/country-codes";
import ReactSelect from "react-select";
import { zipCdes } from "../../config/zipcodeConfig";
import Select from "react-select";
// import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";

// const animatedComponents = makeAnimated();

const AddProviderModal = ({
  isOpen,
  toggle,
  isEdit,
  editDetails,
  fetchAllProvider,
  services,
}) => {
  const userData = useSelector((state) => state?.userCredential?.user);

  // countyList
  const countyList = userData?.countyList?.length
    ? userData?.countyList.map((each) => {
      return {
        label: each,
        value: each,
      };
    })
    : config?.county.map((each) => {
      return {
        label: each,
        value: each,
      };
    });

  //   // serviceList
  //   const serviceList = [
  //     {
  //       label: "House Cleaning",
  //       value: "housecleaning",
  //     },
  //     {
  //       label: "Pedicures",
  //       value: "Pedicures",
  //     },
  //   ];

  const _closeModal = () => {
    setFormData({
      contactName: "",
      providerName: "",
      providerEmail: "",
      countryCode: "+1",
      providerPhone: "",
      providerAddress: "",
      providerWebsiteUrl: "",
      county: [],
      service: [],
      isDiscount: "",
      isNonProfit: false,
    });
    setIsDirty({
      contactName: false,
      providerName: false,
      providerEmail: false,
      countryCode: false,
      providerPhone: false,
      providerAddress: false,
      providerWebsiteUrl: false,
      county: false,
      service: false,
      isDiscount: false,
    });
    setErrors({});
    toggle();
    // setCountyData([initialObj]);
  };
  //state
  const [formData, setFormData] = useState({
    contactName: "",
    providerName: "",
    providerEmail: "",
    countryCode: "+1",
    providerPhone: "",
    providerAddress: "",
    providerWebsiteUrl: "",
    county: [],
    service: [],
    isDiscount: "",
    isNonProfit: false,
  });

  const [prevData, setPrevData] = useState({
    contactName: "",
    providerName: "",
    providerEmail: "",
    countryCode: "+1",
    providerPhone: "",
    providerAddress: "",
    providerWebsiteUrl: "",
    county: [],
    service: [],
    isDiscount: "",
    isNonProfit: false,
  });

  const [isDirty, setIsDirty] = useState({
    contactName: false,
    providerName: false,
    providerEmail: false,
    countryCode: false,
    providerPhone: false,
    providerAddress: false,
    providerWebsiteUrl: false,
    county: false,
    service: false,
    isDiscount: false,
  });

  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);

  // const initialObj = {
  //   county: "",
  //   isValid: false,
  //   error: "",
  //   isDirty: false,
  //   zipCode: "",
  //   isZipCodeValid: true,
  //   isZipCodeError: "",
  //   isZipCodeDirty: false,
  //   zipCodesState: [],
  //   countyDeatils: {},
  // };

  // const [countyData, setCountyData] = useState([initialObj]);
  // const [prevCountyData, setPrevCountyData] = useState([]);

  // const [countyTooltipOpen, setCountyTooltipOpen] = useState(
  //   Array(countyData.length).fill(false)
  // );

  // const _countyToggleView = (index) => {
  //   const newTooltips = [...countyTooltipOpen];
  //   newTooltips[index] = !newTooltips[index];
  //   setCountyTooltipOpen(newTooltips);
  // };

  // serviceList
  const serviceList = services?.map((each) => {
    // console.log("service",each);
    return {
      id: each?._id,
      label: each?.serviceName,
      value: each?.serviceName,
    };
  });

  const _addProviderHandler = async () => {
    const newIsDirty = {
      contactName: true,
      providerName: true,
      providerEmail: true,
      countryCode: true,
      providerPhone: true,
      providerAddress: true,
      providerWebsiteUrl: true,
      county: true,
      service: true,
      isDiscount: true,
    };
    const error = _validateForm(formData, newIsDirty);
    // _validate(countyData);

    // let hasError = true;
    // for (let i = 0; i < countyData.length; i++) {
    //   // console.log(">>>>>>>", countyData[i].isValid);
    //   if (!countyData[i].isValid) {
    //     // console.log("1", hasError);
    //     hasError = true;
    //     break;
    //   } else {
    //     hasError = false;
    //   }
    //   // if (!countyData[i].isZipCodeValid) {
    //   //   hasError = true;
    //   //   // console.log("2", hasError);
    //   //   break;
    //   // } else {
    //   //   hasError = false;
    //   // }
    // }
    // console.log("hasError", hasError);

    console.log("errors => ", error);
    let countyDetails = formData?.county?.map((data) => zipCdes?.find((countyData) => countyData?.county === data))

    if (!error) {
      console.log(">>>", formData);
      setFlag(true);
      let ProviderName = formData?.contactName?.split(" ");
      let name = { first: ProviderName[0], last: ProviderName[1] };
      let newphone = formData.countryCode.concat("-", formData.providerPhone);
      let finalPayload = {
        ...formData,
        contactName: name,
        providerPhone: newphone,
        // county: countyData?.map((data) => data?.countyDeatils),
        county: countyDetails,
        isDiscount: formData?.isDiscount === "yes" ? true : false,
      };

      delete finalPayload.countryCode;

      if (editDetails) {
        // console.log("formdata", formData);

        try {
          const res = await editProvider(editDetails?._id, finalPayload);
          console.log("createProvider", res);
          fetchAllProvider();
          _closeModal();
          showToast(
            res.message && res.message.length
              ? res.message
              : "Provider Updated Successfully!",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          showToast(
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        }
      } else {
        try {
          const res = await createProvider(finalPayload);
          console.log("createProvider", res);
          fetchAllProvider();
          _closeModal();
          showToast(
            res.message && res.message.length
              ? res.message
              : "Provider Created Successfully!",
            "success"
          );
        } catch (error) {
          console.log("error", error);
          showToast(
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        }
      }
      setFlag(false);
    }
  };

  const _duplicateCheck = async (field, value) => {
    let fieldName =
      field === "providerName"
        ? "compnayname"
        : field === "providerEmail"
          ? "email"
          : field === "providerPhone"
            ? "phone"
            : "url";

    let payload = { [field]: value };
    try {
      const res = await duplicate(fieldName, payload);
      console.log("res", res);
      return res.error;
    } catch (error) {
      console.log("error", error);
      return error.error;
    }
  };

  //onChange
  const _handelOnChange = async (field, value) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };
    const newErrors = { ...errors };

    newFormData[field] = value;

    newIsDirty[field] = true;

    setFormData(newFormData);

    setIsDirty(newIsDirty);
    setErrors(newErrors);
    // _validateForm(newFormData, newIsDirty);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "contactName" && dirties[each]) {
        if (!data.contactName.trim()) {
          newErrors.contactName = "*Required";
        } else if (
          data.contactName.trim().length < 2 ||
          data.contactName.length > 25
        ) {
          newErrors.contactName =
            "*Name should be greater than 2 characters and less than 25 characters";
        } else if (
          data.contactName.trim().length &&
          !new RegExp(regexConfig.name).test(data.contactName)
        ) {
          newErrors.contactName =
            "*Numeric and Special Symbols not allowed. Please enter a valid name";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "providerName" && dirties[each]) {
        if (!data?.providerName) {
          newErrors.providerName = "*Required";
        } else if (
          data.providerName.trim().length < 2 ||
          data.providerName.trim().length > 150
        ) {
          newErrors.providerName =
            "*ProviderName should be greater than 2 characters and less than 150 characters";
        } else if (
          data.providerName.trim().length &&
          !new RegExp(regexConfig.name).test(data.providerName)
        ) {
          newErrors.providerName =
            "*Numeric not allowed. Please enter a valid name";
        } else {
          if (editDetails) {
            if (data?.providerName !== editDetails?.providerName) {
              optimizedFn(each, data.providerName, newErrors);
            }
          } else {
            optimizedFn(each, data.providerName, newErrors);
          }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "providerEmail" && dirties[each]) {
        if (!data?.providerEmail) {
          newErrors.providerEmail = "*Required";
        } else if (data.providerEmail.trim().length > 40) {
          newErrors.providerEmail = "*Invalid Email";
        } else if (
          data.providerEmail.trim().length &&
          !new RegExp(regexConfig.email).test(data.providerEmail)
        ) {
          newErrors.providerEmail = "*Please enter a valid email ID";
        } else {
          if (editDetails) {
            if (data?.providerEmail !== editDetails?.providerEmail) {
              optimizedFn(each, data.providerEmail, newErrors);
            }
          } else {
            optimizedFn(each, data.providerEmail, newErrors);
          }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "providerPhone" && dirties[each]) {
        if (!data?.providerPhone) {
          newErrors.providerPhone = "*Required";
        } else if (
          data.providerPhone.trim().length < 8 ||
          data.providerPhone.trim().length > 12
        ) {
          newErrors.providerPhone =
            "*Only Numbers allowed min 8 - max 12 digits";
        } else if (
          data.providerPhone.trim().length &&
          !new RegExp(regexConfig.phone).test(data.providerPhone)
        ) {
          newErrors.providerPhone = "*Please enter a valid phone number";
        } else {
          if (editDetails) {
            if (
              data?.providerPhone !== editDetails.providerPhone.split("-")[1]
            ) {
              optimizedFn(each, data.providerPhone, newErrors);
            }
          } else {
            optimizedFn(each, data.providerPhone, newErrors);
          }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "providerAddress" && dirties[each]) {
        if (!data?.providerAddress) {
          newErrors.providerAddress = "*Required";
        } else if (
          data.providerAddress.trim().length < 8 ||
          data.providerAddress.trim().length > 50
        ) {
          newErrors.providerAddress =
            "*Address allowed min 8 - max 50 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "providerWebsiteUrl" && dirties[each]) {
        if (!data?.providerWebsiteUrl) {
          newErrors.providerWebsiteUrl = "*Required";
        } else if (
          data.providerWebsiteUrl.trim().length &&
          !new RegExp(regexConfig.url).test(data.providerWebsiteUrl)
        ) {
          newErrors.providerWebsiteUrl = "*Please enter a valid url";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        //   if (editDetails) {
        //     if (data?.providerWebsiteUrl !== editDetails?.providerWebsiteUrl) {
        //       optimizedFn(each, data.providerWebsiteUrl, newErrors);
        //     }
        //   } else {
        //     optimizedFn(each, data.providerWebsiteUrl, newErrors);
        //   }
        }
        // else {
        //   delete newErrors[each];
        //   dirties[each] = false;
        // }
      } else if (each === "county" && dirties[each]) {
        if (!data?.county?.length) {
          newErrors.county = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "service" && dirties[each]) {
        if (!data?.service?.length) {
          newErrors.service = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "isDiscount" && dirties[each]) {
        if (!data?.isDiscount?.length) {
          newErrors.isDiscount = "*Required";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const handleCountryChange = (newValue) => {
    // selectedOption, { action, name, index }
    // console.log("Selected Value:", selectedOption.value);
    // console.log("Selected Label:", selectedOption.label);
    // console.log("Index:", index);
    // console.log("Action:", action);
    // console.log("Name:", name);

    // setCountyData((prevData) => {
    //   const newArray = [...prevData];
    //   newArray[index][name] = selectedOption.value;
    //   newArray[index].isZipCodeDirty = true;

    //   const filteredData = zipCdes.filter(
    //     (data) => data?.county === selectedOption.value
    //   );
    //   newArray[index]["zipCodesState"] = filteredData;
    //   newArray[index]["zipCode"] = filteredData[0]?.postalCode
    //   newArray[index]["countyDeatils"] = filteredData[0]

    //   return newArray;
    // });
    // _validate();

    let newFormData = formData;
    const newIsDirty = { ...isDirty };
    newIsDirty.county = true;

    newFormData.county = newValue?.map((item) => {
      return item.value;
    });
    // }
    setFormData({ ...formData, ...newFormData });
    _validateForm(formData, newIsDirty);
  };

  const handleVeteransChange = (newValue, actionMeta) => {
    // console.log("newValue >>", newValue, actionMeta);

    let newFormData = formData;
    const newIsDirty = { ...isDirty };
    newIsDirty.service = true;

    // if(editDetails && actionMeta === "remove-value"){
    //   console.log(">>>>>>>>>>>>");
    //   let nwewValue = prevData.service.filter(({ id: id1 }) => !newValue.removedValue.some(({ id: id2 }) =>  id2 === id1))
    //   console.log("deleteId", nwewValue);
    // }
    // else{
    newFormData.service = newValue?.map((item) => {
      return item.id;
    });
    // }
    setFormData({ ...formData, ...newFormData });
    _validateForm(formData, newIsDirty);

    // console.log(">>>>>.",prevData.service.filter(({ id: id1 }) => !newValue.some(({ id: id2 }) => id2 === id1)));
  };

  const [tooltipOpen, setTooltipOpen] = useState([false, false]);
  const _toggle = (i) => {
    const newArray = tooltipOpen.map((each, index) => {
      return index === i ? !each : false;
    });
    setTooltipOpen(newArray);
  };

  const _checkHandler = async (field, value, allFieldError) => {
    const newIsDirty = { ...isDirty };
    const newErrors = { ...allFieldError };
    setFlag(true);
    let dup = await _duplicateCheck(field, value);
    console.log("dup", dup);

    if (dup) {
      newErrors[field] = "Already Exist";
    } else {
      delete newErrors[field];
      newIsDirty[field] = false;
    }
    setFlag(false);
    setErrors(newErrors);
    setIsDirty(newIsDirty);
  };

  const optimizedFn = useCallback(debounce(_checkHandler), []);

  // const _addInput = () => {
  //   let newArray = deepClone(countyData);
  //   newArray.push({
  //     county: "",
  //     isValid: false,
  //     error: "",
  //     isDirty: false,
  //     zipCode: "",
  //     zipCodesState: [],
  //     isZipCodeValid: true,
  //     isZipCodeError: "",
  //     isZipCodeDirty: false,
  //     countyDeatils: {},
  //   });
  //   setCountyData(newArray);
  // };

  // const _deleteInput = (index) => {
  //   let newArray = deepClone(countyData);
  //   newArray.splice(index, 1);
  //   setCountyData(newArray);
  // };

  // const _onChangeHandler = (key, value, index) => {
  //   setCountyData((prevData) => {
  //     const newArray = [...prevData];
  //     newArray[index][key] = value;
  //     // newArray[index].isZipCodeDirty = true;

  //     const countyData = zipCdes.filter(
  //       (data) => data?.postalCode === Number(value)
  //     );
  //     newArray[index].countyDeatils = countyData[0];

  //     return newArray;
  //   });
  //   // _validate();
  // };

  // const _validate = () => {
  //   setCountyData((prevData) => {
  //     const newArray = [...prevData];

  //     const upadtedArray = newArray.map((data) => {
  //       if (data?.county?.length) {
  //         data.error = "";
  //         data.isValid = true;
  //       } else {
  //         data.error = "*Required";
  //         data.isValid = false;
  //         data.isDirty = true;
  //       }

  //       // if (data?.zipCode?.length) {
  //       //   data.isZipCodeError = "";
  //       //   data.isZipCodeValid = true;
  //       // } else {
  //       //   data.isZipCodeError = "*Required";
  //       //   data.isZipCodeValid = false;
  //       //   data.isZipCodeDirty = true;
  //       // }
  //       return data;
  //     });
  //     return upadtedArray;
  //   });
  // };

  useEffect(() => {
    if (editDetails !== null) {
      // console.log("editDetails", editDetails);
      let phoneNumber = editDetails.providerPhone.split("-");

      setFormData({
        contactName: editDetails?.contactName?.full,
        providerName: editDetails?.providerName,
        providerEmail: editDetails?.providerEmail,
        countryCode: phoneNumber[0],
        providerPhone: phoneNumber[1],
        providerAddress: editDetails?.providerAddress,
        providerWebsiteUrl: editDetails?.providerWebsiteUrl,
        county: editDetails?._county?.map((data) => data?.countyName),
        service: editDetails?._service,
        isDiscount: editDetails?.isDiscount ? "yes" : "no",
        isNonProfit: editDetails?.isNonProfit === true ? true : false,
      });
      setPrevData({
        contactName: editDetails?.contactName?.full,
        providerName: editDetails?.providerName,
        providerEmail: editDetails?.providerEmail,
        countryCode: phoneNumber[0],
        providerPhone: phoneNumber[1],
        providerAddress: editDetails?.providerAddress,
        providerWebsiteUrl: editDetails?.providerWebsiteUrl,
        county: editDetails?._county?.map((data) => data?.countyName),
        service: editDetails?._service,
        isDiscount: editDetails?.isDiscount ? "yes" : "no",
        isNonProfit: editDetails?.isNonProfit === true ? true : false,
      });

      // let arrayData = editDetails?._county?.map((data) => ({
      //   county: data?.countyName,
      //   isValid: true,
      //   error: "",
      //   isDirty: false,
      //   zipCode: data?.postalCode,
      //   isZipCodeValid: true,
      //   isZipCodeError: "",
      //   isZipCodeDirty: false,
      //   zipCodesState: zipCdes.filter(
      //     (codes) => codes?.county === data?.countyName
      //   ),
      //   countyDeatils: data,
      // }));
      // setCountyData(arrayData);
      // setPrevCountyData(arrayData);
    }
  }, [editDetails]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={_closeModal}
        centered
        scrollable
        size="lg"
      >
        <ModalHeader>{isEdit ? "Update Provider" : "Add Provider"}</ModalHeader>
        <ModalBody>
          <Form>
            {/* ==== Primary Contact Name ==== */}
            <FormGroup>
              <Label>Primary Contact Name</Label>
              <Input
                type="text"
                placeholder="Enter"
                autoComplete="off"
                value={formData?.contactName}
                onChange={(event) =>
                  _handelOnChange("contactName", event.target.value)
                }
                onBlur={() => _onBlurFormFields("contactName")}
              />
              {errors.contactName ? (
                <div className="form-error">{errors.contactName}</div>
              ) : null}
            </FormGroup>

            {/* ==== Company Name ==== */}
            <FormGroup>
              <Label>Company Name</Label>
              <Input
                type="text"
                placeholder="Enter"
                autoComplete="off"
                value={formData?.providerName}
                onChange={(event) =>
                  _handelOnChange("providerName", event.target.value)
                }
                onBlur={() => _onBlurFormFields("providerName")}
              />
              {errors.providerName ? (
                <div className="form-error">{errors.providerName}</div>
              ) : null}
            </FormGroup>

            {/* ==== Email ==== */}
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Enter"
                autoComplete="off"
                value={formData?.providerEmail}
                onChange={(event) =>
                  _handelOnChange("providerEmail", event.target.value)
                }
                onBlur={() => _onBlurFormFields("providerEmail")}
              />
              {errors.providerEmail ? (
                <div className="form-error">{errors.providerEmail}</div>
              ) : null}
            </FormGroup>

            {/* ==== Phone ==== */}
            <FormGroup>
              <Label>Phone</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Input
                    type="select"
                    placeholder="Enter"
                    value={formData?.countryCode}
                    onChange={(event) =>
                      _handelOnChange("countryCode", event.target.value)
                    }
                  >
                    {/* <option value="">select</option> */}
                    {countryCodes.map((each) => (
                      <option key={each.code} value={each.dial_code}>
                        {each.code} ({each.dial_code})
                      </option>
                    ))}
                  </Input>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="Enter"
                  autoComplete="off"
                  value={formData?.providerPhone}
                  onChange={(event) =>
                    _handelOnChange("providerPhone", event.target.value)
                  }
                  onBlur={() => _onBlurFormFields("providerPhone")}
                />
              </InputGroup>
              {errors.providerPhone ? (
                <div className="form-error">{errors.providerPhone}</div>
              ) : null}
            </FormGroup>

            {/* ==== Addtess ==== */}
            <FormGroup>
              <Label>Address</Label>
              <Input
                type="text"
                placeholder="Enter"
                autoComplete="off"
                value={formData?.providerAddress}
                onChange={(event) =>
                  _handelOnChange("providerAddress", event.target.value)
                }
                onBlur={() => _onBlurFormFields("providerAddress")}
              />
              {errors.providerAddress ? (
                <div className="form-error">{errors.providerAddress}</div>
              ) : null}
            </FormGroup>

            {/* ==== Website URL ==== */}
            <FormGroup>
              <Label>Website URL</Label>
              <Input
                type="text"
                placeholder="Enter"
                autoComplete="off"
                value={formData?.providerWebsiteUrl}
                onChange={(event) =>
                  _handelOnChange("providerWebsiteUrl", event.target.value)
                }
                onBlur={() => _onBlurFormFields("providerWebsiteUrl")}
              />
              {errors.providerWebsiteUrl ? (
                <div className="form-error">{errors.providerWebsiteUrl}</div>
              ) : null}
            </FormGroup>

            {/* {countyData?.map((data, index) => ( */}
              <FormGroup className="mb-0 mr-2">
                <Label className="d-flex align-items-center mr-2">
                  Select the County or Counties your serve
                  <i
                    id="county_strong_veteran"
                    className="fa fa-info-circle ml-1"
                    // onMouseOver={() => _countyToggleView(index)}
                    // onMouseOut={() => _countyToggleView(index)}
                  />
                  <Tooltip
                    autohide={false}
                    placement="right"
                    isOpen={tooltipOpen[0]}
                    target="county_strong_veteran"
                    toggle={() => _toggle(0)}
                  >
                    Resources county!
                  </Tooltip>
                </Label>
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <Select
                      isSearchable={true}
                      closeMenuOnSelect={false}
                      // components={animatedComponents}
                      defaultValue={
                        editDetails
                          ? formData?.county?.map((each, index) => ({
                            id: index,
                            label: each,
                            value: each,
                          }))
                          : []
                      }
                      isMulti={true}
                      options={countyList}
                      // onChange={(selectedOption, { action, name }) =>
                      //   handleCountryChange(selectedOption, {
                      //     action,
                      //     name: "county",
                      //     index,
                      //   })
                      // }
                      onChange={handleCountryChange}
                      onBlur={() => _onBlurFormFields("county")}
                      placeholder="Select all that apply"
                    />
                   {errors.county ? (
                <div className="form-error">{errors.county}</div>
              ) : null}
                  </div>
                  {/* <div className="col-md-4">
                    <Input
                      type="select"
                      name="zipcode"
                      disabled={!data?.zipCodesState?.length}
                      value={data?.zipCode}
                      onChange={(e) =>
                        _onChangeHandler("zipCode", e.target.value, index)
                      }
                    >
                      
                      {data?.zipCodesState?.map((code, index) => (
                        <option key={index} value={code?.postalCode}>
                          {code?.postalCode}
                        </option>
                      ))}
                    </Input>
                    {data.isZipCodeDirty && !data.isZipCodeValid ? (
                      <div className="form-error">Required!</div>
                    ) : null}
                  </div> */}
                  {/* <div className="col-md-2">
                    {index === 0 ? (
                      <img
                        src={require("../../assets/img/add.png")}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginLeft: "5px",
                        }}
                        onClick={() => (flag ? null : _addInput())}
                      />
                    ) : (
                      <img
                        src={require("../../assets/img/minus.png")}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginLeft: "5px",
                        }}
                        onClick={() => (flag ? null : _deleteInput(index))}
                      />
                    )}
                  </div> */}
                </div>
              </FormGroup>
            {/* ))} */}
            <FormGroup className="mb-0 mr-2 mt-3">
              <Label className="d-flex align-items-center">
                Select the Resource or Resources You Provide
                <i
                  className="fa fa-info-circle ml-1"
                  id="service_strong_veteran_page"
                />
                <Tooltip
                  autohide={false}
                  placement="right"
                  isOpen={tooltipOpen[1]}
                  target="service_strong_veteran_page"
                  toggle={() => _toggle(1)}
                >
                  Provide Veterans!
                </Tooltip>
              </Label>
              <div className="react-select">
                <ReactSelect
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  // components={animatedComponents}
                  //   defaultValue={[serviceList[1]]}
                  defaultValue={
                    editDetails
                      ? formData?.service?.map((each) => ({
                        id: each?.id,
                        label: each?.serviceName,
                        value: each?.serviceName,
                      }))
                      : []
                  }
                  isMulti
                  options={serviceList}
                  onChange={handleVeteransChange}
                  onBlur={() => _onBlurFormFields("service")}
                  placeholder="Select all that apply"
                />
              </div>
              {errors.service ? (
                <div className="form-error">{errors.service}</div>
              ) : null}
            </FormGroup>
            <FormGroup className="mt-3">
              <CustomInput
                className="custom-color"
                type="checkbox"
                id="exampleCustomCheckbox"
                label="Are You a Non Profit?"
                checked={formData?.isNonProfit}
                onChange={(event) =>
                  _handelOnChange("isNonProfit", event.target.checked)
                }
              />
            </FormGroup>

            <FormGroup>
              <Label>Do you want to give veteran discount?</Label>
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div className="form-check">
                  <Input
                    type="radio"
                    name="isDiscount"
                    id="discountYes"
                    checked={formData?.isDiscount === "yes"}
                    onChange={() => _handelOnChange("isDiscount", "yes")}
                    onBlur={() => _onBlurFormFields("isDiscount")}
                    className="form-check-input"
                  />
                  <Label
                    check
                    htmlFor="discountYes"
                    className="form-check-label"
                  >
                    Yes
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="radio"
                    name="isDiscount"
                    id="discountNo"
                    checked={formData?.isDiscount === "no"}
                    onChange={() => _handelOnChange("isDiscount", "no")}
                    onBlur={() => _onBlurFormFields("isDiscount")}
                    className="form-check-input"
                  />
                  <Label
                    check
                    htmlFor="discountNo"
                    className="form-check-label"
                  >
                    No
                  </Label>
                </div>
              </div>

              {errors.isDiscount ? (
                <div className="form-error">{errors.isDiscount}</div>
              ) : null}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => _addProviderHandler()}
            disabled={
              isEdit
                ? (JSON.stringify(prevData) === JSON.stringify(formData) ) ||
                flag === true
                : flag === true
            }
          >
            {isEdit ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddProviderModal;
