import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Row, Col } from "reactstrap";
// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const PaginatedItems = ({
  itemsPerPage,
  totalCount,
  selectedPage,
  onPageChange,
  itemsPerPages,
}) => {
  // eslint-disable-next-line
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.

    setPageCount(Math.ceil(totalCount / itemsPerPage));
  }, [itemsPerPage, totalCount]);

  // Invoke when user click to request another page.
  const _handlePageClick = (event) => {
    onPageChange(event.selected);
  };

  if (!totalCount) return <></>;

  return (
    <>
      <Row className="mt-3 align-items-center">
        <Col md={6}>
          <div className="tableCount">
            {/* Showing {totalCount} entries out of {itemsPerPages} entries */}
          </div>
        </Col>
        <Col md={6}>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={_handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination justify-content-end"
          />
        </Col>
      </Row>
    </>
  );
};

export default PaginatedItems;
