import React, { useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  ModalFooter,
  ButtonDropdown,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const NewTextTemplate = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-dialog-centered"
      >
        <ModalHeader>New Text Templates</ModalHeader>
        <ModalBody>
          {/* Tamplate Name */}
          <FormGroup className="mb-3">
            <Label for="TemplateName">Template Name</Label>
            <Input
              type="text"
              name="name"
              id="TemplateName"
              // value={smsData.name}
              // onChange={(e) => _handleChange(e)}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <Label for="templateType">Category</Label>
            <Input
              type="select"
              name="templateType"
              id="templateType"
              // value={smsData.templateType}
              // onChange={(e) => _handleChange(e)}
            >
              <option value="" selected disabled>
                -Select-
              </option>
              <option value="Pre Quote">Pre Quote</option>
              <option value="Quote">Quote</option>
            </Input>

            {/* <div className="form-error"> Required </div> */}
          </FormGroup>

          <FormGroup row className="mb-3">
            <Col lg={12} md={12}>
              <div className="variables_textarea">
                <Label className="mb-0">Message</Label>

                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>Insert variables</DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>{`user-email`}</DropdownItem>

                    <DropdownItem>{`user-name`}</DropdownItem>

                    <DropdownItem>{`user-phone`}</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>

              <Input
                type="textarea"
                name="bodyText"
                style={{ height: 90 }}
                // value={smsData.bodyText}
                // onChange={(e) => _handleChange(e)}
              />

              <div className="d-flex justify-content-between">
                <span className="error-message">
                  <div className="form-error"> required </div>
                </span>

                <div className="form-error text-right w-100">0</div>
              </div>
            </Col>

            {/* <div className="form-error"> Required </div> */}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              props.toggle();
            }}
          >
            Cancel
          </Button>

          <Button color="primary" onClick={() => props.toggle}>
            Save Template
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NewTextTemplate;
