import React, { useState, useEffect } from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Row,
  Col,
  FormGroup,
  CustomInput,
} from "reactstrap";
import CreateTeam from "../components/Modal/CreateTeam";
// import PaginationWrap from "../components/PaginationWrap";
import {
  deleteOneMember,
  fetchAllMembers,
  userStatus,
} from "../http/http-calls";
import { deepClone, showToast } from "../helper-methods";
import DeleteAlertModal from "../components/Modal/DeleteAlertModal";
import PaginatedItems from "../components/PaginatedItems";
import { useSelector } from "react-redux";
import SvgIcons from "../components/SvgIcons";

const Team = () => {
  /* eslint-disable */
  const userData = useSelector((state) => state?.userCredential?.user);

  const [teamModal, SetTeamModal] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [delModeOpen, setDelModeOpen] = useState(false);
  const [deleteId, setDelId] = useState("");
  const [editDetails, setEditDetails] = useState(null);
  const [payload, setPayload] = useState({
    pageNumber: 1,
    pageSize: 8,
  });
  const [count, setCount] = useState("");
  const [itemsPerPages, setItemsPerPages] = useState("");
  const [flag, setFlag] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const _onTeamToggle = (isOpen = false) => {
    SetTeamModal(isOpen);
    setEditDetails(null);
  };

  const _fetchAllTeamMembers = async (newPayload) => {
    try {
      const res = await fetchAllMembers(newPayload ? newPayload : payload);
      console.log("teamMembers>>", res);
      setTeamMembers(res?.data);
      setCount(res?.usersCount);
      setItemsPerPages(res?.data?.length);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };
  useEffect(() => {
    _fetchAllTeamMembers();
  }, []);

  // console.log(">>",teamMembers);

  //edit

  const _editHandler = (details) => {
    _onTeamToggle(true);
    setEditDetails(details);
  };

  // const debounce = (func) => {
  //   let timer;
  //   return function (...args) {
  //     const context = this;
  //     if (timer) clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       func.apply(context, args);
  //     }, 500);
  //   };
  // };

  //filter and search
  const _statusHandler = async (field, event) => {
    event.persist();
    const newSearchData = payload;
    newSearchData[field] = event.target.value;

    if (event.target.value?.length) {
      setPayload[field] = event.target.value;
      setPayload({ ...payload, pageNumber: 1 });
    } else {
      delete newSearchData[field];
      _fetchAllTeamMembers();
    }

    // _checkHandler(newSearchData);
  };

  //debouncing
  useEffect(() => {
    if (payload.hasOwnProperty("searchText")) {
      const _checkHandler = setTimeout(async () => {
        try {
          const res = await fetchAllMembers(payload);
          console.log("searchFilter>>", res);
          setTeamMembers(res?.data);
          setCount(res?.usersCount);
          setItemsPerPages(res?.data?.length);
        } catch (error) {
          console.log(error);
        }
      }, 500);
      return () => clearTimeout(_checkHandler);
    }
  }, [payload]);

  //status change
  const _changeStatus = async (event, id, index) => {
    setFlag(true);
    console.log("id", id);
    let oldReports = JSON.parse(JSON.stringify(teamMembers));

    const value = event.target.checked;
    teamMembers[index].isActive = value;
    setTeamMembers([...teamMembers]);

    try {
      const res = await userStatus(id, { status: value });
      console.log("resForUpdatingStatus>>", res);
      showToast(
        res.message && res.message.length
          ? res.message
          : "User Status Successfully Updated!",
        "success"
      );
      // _fetchAllTeamMembers();
    } catch (error) {
      setTeamMembers(oldReports);
      console.log(error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
    setFlag(false);
  };

  //delete

  const _deleteToggle = (delModeOpen = false, id) => {
    setDelModeOpen(delModeOpen);
    setDelId(id);
  };

  const _removeUser = async (memberId) => {
    // console.log(memberId);
    _deleteToggle(true, memberId);
  };

  //delete user handler
  const _deleteHandler = async () => {
    try {
      const res = await deleteOneMember(deleteId);
      console.log("deleteUser", res);
      _fetchAllTeamMembers();
      showToast(
        res.message && res.message.length
          ? res.message
          : "User Created Successfully!",
        "success"
      );
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  //pagination
  const _handlePageClick = (pageNumber) => {
    // console.log(pageNumber);
    let newPageConfig = deepClone(payload);
    newPageConfig["pageNumber"] = pageNumber + 1;
    setPayload(newPageConfig);
    _fetchAllTeamMembers(newPageConfig);
  };

  const _clearAllHandler = () => {
    document.getElementById("searchText").value = "";
    
    setPayload({
      pageNumber: 1,
      pageSize: 10,
    });
    _fetchAllTeamMembers({
      pageNumber: 1,
      pageSize: 10,
    });
  }

  return (
    <>
      <div className="InnerWrapper">
        <div className="PgTitle">
          <h2 className="pg-heading">Team</h2>
          <div className="right">
            {userData?.team?.add ? (
              <Button
                color="primary"
                onClick={() => {
                  _onTeamToggle(true);
                }}
              >
                Create New
              </Button>
            ) : null}
            <Button
              color="link"
              className="filterIcon"
              onClick={() => setShowFilter(!showFilter)}
            >
              <SvgIcons type={"filter"} />
            </Button>
          </div>
        </div>

        {/* filters */}
        <div
          className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
        >
          <h3>Filters</h3>

          <div className="filterWarp">
            <FormGroup className="searchBox">
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  autoComplete="off"
                  id="searchText"
                  onChange={(event) => _statusHandler("searchText", event)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText data-test="search-button">
                    <i className="icon-magnifier"></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <Button
              color="danger"
              className="reset h-auto"
              outline
              onClick={() => _clearAllHandler()}
            >
              <i className="fa fa-eraser"></i>
            </Button>
          </div>
        </div>

        <div className="userList">
          <Row>
            {teamMembers?.length ? (
              teamMembers?.map((data, index) => (
                <Col xl={3} lg={6} key={index}>
                  <div className="user_box">
                    <div className="user_image">
                      <div className="Image">
                        <img
                          src={
                            data?.profilePicture
                              ? data?.profilePicture
                              : require("../assets/img/default_user_icon.svg")
                          }
                          alt="avatar"
                          loading="lazy"
                        />
                      </div>
                      <div className="switch_box">
                        <CustomInput
                          type="switch"
                          id={data._id}
                          checked={data.isActive}
                          disabled={flag || !userData?.team?.edit}
                          onChange={(event) =>
                            _changeStatus(event, data._id, index)
                          }
                        />
                      </div>
                    </div>
                    <div className="user_content">
                      <h1>
                        {data?.name?.full}
                        {userData?.team?.edit ? (
                          <span>
                            <i
                              title="Update Member"
                              className="fa fa-pencil edit"
                              onClick={() => _editHandler(data)}
                            />
                            <i
                              title="Remove Member"
                              className="fa fa-trash detele"
                              onClick={() => _removeUser(data?._id)}
                            />
                          </span>
                        ) : null}
                      </h1>
                      <ul>
                        <li>
                          <img
                            src={require("../assets/img/call.png")}
                            alt="phone"
                            loading="lazy"
                          />
                          {data?.phone}
                        </li>
                        <li>
                          <img
                            src={require("../assets/img/email.png")}
                            alt="phone"
                            loading="lazy"
                          />
                          {data?.email}
                        </li>
                        <li>
                          <img
                            src={require("../assets/img/pin.png")}
                            alt="phone"
                            loading="lazy"
                          />
                          {data.location}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="noData">
                <img src={require("../assets/img/noData.svg")} alt="no data" />
                <p>There is no data to display</p>
              </div>
            )}
          </Row>
          {/* <PaginationWrap 
            count={count}
            itemsPerPages={itemsPerPages}
            currentPage={payload.pageNumber}
          /> */}
          <PaginatedItems
            itemsPerPages={itemsPerPages}
            totalCount={count}
            selectedPage={payload.pageNumber}
            itemsPerPage={payload.pageSize}
            onPageChange={_handlePageClick}
          />
        </div>
      </div>

      {teamModal && (
        <CreateTeam
          fetchAllUsers={_fetchAllTeamMembers}
          isEdit={editDetails === null ? false : true}
          editDetails={editDetails}
          isOpen={teamModal}
          toggle={() => _onTeamToggle()}
        />
      )}
      <DeleteAlertModal
        delModeOpen={delModeOpen}
        deleteUser={() => _deleteHandler()}
        toggle={() => _deleteToggle()}
      />
    </>
  );
};

export default Team;
