import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const DeleteAlertModal = ({
  delModeOpen,
  toggle,
  deleteUser,
  deleteService,
  deleteProvider,
  logout,
}) => {
  const _closeModal = () => {
    toggle();
  };
  const _removeUser = () => {
    if (deleteUser) {
      deleteUser();
    }

    if (deleteService) {
      deleteService();
    }
    if (logout) {
      // logout();
    }
    if (deleteProvider) {
      deleteProvider();
    }

    // console.log("deleted");
    _closeModal();
  };

  return (
    <Modal centered isOpen={delModeOpen} toggle={() => _closeModal()}>
      <ModalBody>
        <p className="fs-20 m-0">
          {deleteUser
            ? "Are you sure that you want to delete this User?"
            : deleteService
            ? "Are you sure that you want to delete this Service?"
            : deleteProvider
            ? "Are you sure that you want to delete this Provider?"
            : logout
            ? "Are you sure that you want to Logout?"
            : null}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => _closeModal()}>
          No
        </Button>
        <Button color="primary" type="button" onClick={() => _removeUser()}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default DeleteAlertModal;
