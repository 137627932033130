// import config from "../config";
import { store } from "../redux/store";


export const getToken = () => {
  return new Promise((resolve, reject) => {
    let token = null;
    // const oldState = store.getState();
    // const state = { ...oldState };
    // Try to get token from state
    // if (localStorage[config.TOKEN_NAME]) {
    //   token = localStorage[config.TOKEN_NAME];
    // }
    const { userCredential } = store.getState();

    if (userCredential?.token) {
      token = userCredential.token;
    }
    resolve(token);
  });
};
