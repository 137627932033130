import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  CardBody,
  Card,
  Input,
  Label,
  Table,
} from "reactstrap";
import IndianaCountyMap from "../components/IndianaCountyMap";
import PaginatedItems from "../components/PaginatedItems";
import {
  dashboardServiceFilter,
  getAllDashboardData,
  getManualStats,
  getLastMonthData,
  getLastWeekdData,
  getQtdData,
  getYtdData,
} from "../http/http-calls";
import ReadMoreReadLess from "../components/ReadMoreReadLess";
import { errorHandler } from "../helper-methods";
import ErrorBoundary from "../components/ErrorBoundary";
import CustomDateRangePicker from "../components/CustomDateRangePicker";
import moment from "moment";
import SvgIcons from "../components/SvgIcons";

const Home = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [activeDate, setActiveDate] = useState("");
  const [service, setService] = useState([]);
  const [countyData, setCountyData] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const [providerCurrentCount, setProviderCurrentCount] = useState("");
  const [providerIncrease, setProviderIncrease] = useState("");
  const [providerDecrease, setProviderDecrease] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [searchCurrentCount, setSearchCurrentCount] = useState("");
  const [searchIncrease, setSearchIncrease] = useState("");
  const [searchDecrease, setSearchDecrease] = useState("");

  const [dataPayload, setDataPayload] = useState({
    page: 0,
    limit: 10,
  });

  const [filters, setFilters] = useState({
    dateRange: {
      startDate: null,
      endDate: null,
    },
  });

  const _getManualStats = async (data) => {
    try {
      const res = await getManualStats(data);

      setProviderCurrentCount(res?.container?.providers);
      setSearchCurrentCount(res?.container?.searches);

      setProviderIncrease("");
      setProviderDecrease("");
      setSearchIncrease("");
      setSearchDecrease("");
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onDatesChange = ({ startDate = null, endDate = null }) => {
    try {
      const newFilters = { ...filters };
      newFilters["dateRange"] = {
        startDate,
        endDate,
      };
      setFilters(newFilters);

      if (
        newFilters.dateRange.startDate !== null &&
        newFilters.dateRange.endDate !== null
      ) {
        const newSearchData = {};

        let start = moment(newFilters?.dateRange?.startDate?._d).format(
          "DD/MM/YYYY"
        );
        let end = moment(newFilters?.dateRange?.endDate?._d).format(
          "DD/MM/YYYY"
        );

        newSearchData["dateRange"] = start + "-" + end;

        _getManualStats(newSearchData);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _dataSetHandler = (data) => {
    setProviderCurrentCount(data?.providerCurrentCount);
    setProviderIncrease(data?.providerIncrease);
    setProviderDecrease(data?.providerDecrease);

    setSearchCurrentCount(data?.searchCurrentCount);
    setSearchIncrease(data?.searchIncrease);
    setSearchDecrease(data?.searchDecrease);
  };

  const _lastWeekClickHandler = async (id) => {
    try {
      setActiveDate(id);
      const res = await getLastWeekdData();
      _dataSetHandler(res?.container);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _lastMonthClickHandler = async (id) => {
    try {
      setActiveDate(id);
      const res = await getLastMonthData();
      _dataSetHandler(res?.container);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _qtdClickHandler = async (id) => {
    try {
      setActiveDate(id);
      const res = await getQtdData();
      _dataSetHandler(res?.container);
    } catch (error) {
      errorHandler(error);
    }
  };
  const _ytdClickHandler = async (id) => {
    try {
      setActiveDate(id);
      const res = await getYtdData();
      _dataSetHandler(res?.container);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchDashboardData = async () => {
    try {
      const res = await getAllDashboardData();
      setDashboardData(res?.service);
      setService(res?.service);

      if (res?.map?.length) {
        setCountyData(res.map);
      }

      setActiveDate("last-week");
    } catch (error) {
      errorHandler(error);
    }
  };

  const _serviceChangeHandler = async (value) => {
    try {
      setSelectedService(value);

      if (!value?.length) {
        _fetchDashboardData();
        return;
      }

      const res = await dashboardServiceFilter(value);

      const newCountyData = [];
      if (res?.data?._map?.length) {
        newCountyData.push(...res?.data?._map);
      }
      setCountyData(newCountyData);

      setDashboardData([{ ...res?.data }]);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _clearAllHandler = () => {
    setFilters({
      dateRange: {
        startDate: null,
        endDate: null,
      },
    });
    _lastWeekClickHandler();
  };

  //46711 46856 47232
  useEffect(() => {
    _fetchDashboardData();
    _lastWeekClickHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="InnerWrapper">
        <div className="PgTitle">
          <h2 className="pg-heading">Stats</h2>
          <div className="right">
            <Button
              color="link"
              className="filterIcon"
              onClick={() => setShowFilter(!showFilter)}
            >
              <SvgIcons type={"filter"} />
            </Button>
          </div>
        </div>

        <div
          className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
        >
          <h3>Filters</h3>

          <div className="filterWarp">
            <ButtonGroup>
              <Button
                active={activeDate === "last-week"}
                onClick={() => _lastWeekClickHandler("last-week")}
                id="last-week"
              >
                Last Week
              </Button>
              <Button
                active={activeDate === "last-Month"}
                onClick={() => _lastMonthClickHandler("last-Month")}
                id="last-Month"
              >
                Last Month
              </Button>
              <Button
                active={activeDate === "qtd"}
                onClick={() => _qtdClickHandler("qtd")}
                id="qtd"
              >
                QTD
              </Button>
              <Button
                active={activeDate === "ytd"}
                onClick={() => _ytdClickHandler("ytd")}
                id="ytd"
              >
                YTD
              </Button>
            </ButtonGroup>
            <CustomDateRangePicker
              startDate={filters.dateRange.startDate}
              endDate={filters.dateRange.endDate}
              startDateId={"startDate_kpi_dashboard"}
              endDateId={`endDate_kpi_dashboard`}
              onDatesChange={(startDate, endDate) =>
                _onDatesChange(startDate, endDate)
              }
              isDisabledOutsideRange={true}
              isMaxDateValidation={true}
            />

            <Button
              color="danger"
              className="reset h-auto"
              outline
              onClick={() => _clearAllHandler()}
            >
              <i className="fa fa-eraser"></i>
            </Button>
          </div>
        </div>

        <Row>
          <Col md={6}>
            <Card className="dashboard_card">
              <CardBody>
                <div className="CardInfo">
                  <h3>New Registrations</h3>
                  <span className="Count">
                    {providerCurrentCount ? providerCurrentCount : 0}
                  </span>
                  {providerIncrease ? (
                    <p>
                      <span>
                        <i className="fa fa-arrow-up" />
                        {providerIncrease}%
                      </span>
                      Over last Week
                    </p>
                  ) : providerDecrease ? (
                    <p>
                      <span>
                        <i className="fa fa-arrow-down" />
                        {providerDecrease}%
                      </span>
                      Over last Week
                    </p>
                  ) : null}
                </div>
                <div className="cardImage">
                  <img src={require("../assets/img/Icons-1.png")} alt="user" />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="dashboard_card">
              <CardBody>
                <div className="CardInfo">
                  <h3>Searches</h3>
                  <span className="Count">
                    {searchCurrentCount ? searchCurrentCount : 0}
                  </span>
                  {searchIncrease ? (
                    <p>
                      <span>
                        <i className="fa fa-arrow-up" />
                        {searchIncrease}%
                      </span>
                      Veterans Last Period
                    </p>
                  ) : null}
                  {searchDecrease ? (
                    <p>
                      <span>
                        <i className="fa fa-arrow-up" />
                        {searchDecrease}%
                      </span>
                      Veterans Last Period
                    </p>
                  ) : null}
                </div>
                <div className="cardImage">
                  <img src={require("../assets/img/Icons-2.png")} alt="user" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <div className="innerContent_Wrapper bg-white">
          <Row>
            <Col xl={6}>
              <div className="vendorsWrap">
                <h2>Resources</h2>
                <div>
                  <Label>Select a Resources</Label>
                  <Input
                    type="select"
                    onChange={(e) => _serviceChangeHandler(e.target.value)}
                  >
                    <option value="">All</option>
                    {service?.map((data, index) => (
                      <option value={data?._id} key={index}>
                        {data?.serviceName}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>

              {/* Indiana County Map */}
              <ErrorBoundary>
                <IndianaCountyMap data={countyData} />
              </ErrorBoundary>
            </Col>

            <Col xl={6}>
              <Table responsive className="mt-4">
                <thead>
                  <tr>
                    <th>Name of {selectedService ? "County" : "Resource"}</th>
                    <th>Veterans Interested</th>
                    <th>Searches Made</th>
                    <th>Vendors</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData?.length ? (
                    dashboardData
                      ?.slice(
                        dataPayload.page * dataPayload.limit,
                        dataPayload.page * dataPayload.limit + dataPayload.limit
                      )
                      ?.map((data) =>
                        !selectedService ? (
                          <tr key={data._id}>
                            <td style={{ width: 180, whiteSpace: "wrap" }}>
                              {data?.serviceName ? (
                                <ReadMoreReadLess
                                  text={data?.serviceName}
                                  count={20}
                                />
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td>{data?.numberOfVetSearchByService}</td>
                            <td>{data?.numberOfSearchCountByService}</td>
                            <td>{data?.numberOfvendorsCountByService}</td>
                          </tr>
                        ) : (
                          data?._map?.map((service) => (
                            <tr key={service._id}>
                              <td style={{ width: 180, whiteSpace: "wrap" }}>
                                {service?.countyName ? (
                                  <ReadMoreReadLess
                                    text={service?.countyName}
                                    count={20}
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>{service?.numberOfVetSearchByCounty}</td>
                              <td>{service?.numberOfSearchByCounty}</td>
                              <td>{service?.numberOfVendoresByCounty}</td>
                            </tr>
                          ))
                        )
                      )
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <div className="noData">
                          <img
                            src={require("../assets/img/noData.svg")}
                            alt="no data"
                          />
                          <p>There is no data to display</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              <PaginatedItems
                itemsPerPages={dataPayload.limit}
                totalCount={dashboardData?.length}
                selectedPage={dataPayload.page}
                itemsPerPage={dataPayload.limit}
                onPageChange={(page) =>
                  setDataPayload((prev) => ({ ...prev, page }))
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Home;
