import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
  Input,
  FormGroup,
  Col,
  Row,
  Label,
} from "reactstrap";
import MessageLogs from "../components/Modal/MessageLogs";
import NewEmailTemplate from "../components/Modal/NewEmailTemplate";
import NewTextTemplate from "../components/Modal/NewTextTemplate";
import { fetchAllTemplates } from "../http/http-calls";
import { showToast } from "../helper-methods";

const MessageTemplates = () => {
  // SMS
  const [smsModal, SetSmsModal] = useState(false);
  // Email
  const [emailModal, SetEmailModal] = useState(false);
  // Message Log
  const [messageLogsModal, SetMessageLogsModal] = useState(false);

  // SMS
  const _SmsToggle = (isOpen = false) => {
    SetSmsModal(isOpen);
  };

  // Email
  const _EmailToggle = (isOpen = false) => {
    SetEmailModal(isOpen);
  };

  // Message Log
  const _MesLogToggle = (isOpen = false) => {
    SetMessageLogsModal(isOpen);
  };

  const _fetchAllTemplates = async () => {
    try {
      const res = await fetchAllTemplates();
      console.log("templates", res);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  useEffect(() => {
    _fetchAllTemplates();
  }, []);
  return (
    <>
      <div className="InnerWrapper">
        <div className="PgTitle">
          <h2 className="pg-heading">Message Templates</h2>
          <div className="right">
            <InputGroup className="search-box">
              <Input
                data-test="search-input-filter"
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText data-test="search-button">
                  <i className="icon-magnifier"></i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <Button
              color="primary"
              outline
              onClick={() => {
                _SmsToggle(true);
              }}
            >
              <i className="fa fa-plus"></i>
              New Text Template
            </Button>
            <Button color="primary" onClick={() => _EmailToggle(true)}>
              <i className="fa fa-plus"></i>
              New Email Template
            </Button>
          </div>
        </div>

        <div className="filter_content_wrapper bg-white">
          <div className="left">
            <h3>Filter</h3>
          </div>
          <Row>
            <Col md={3}>
              <FormGroup className="mb-0 mr-3">
                <Label>Status</Label>
                <Input type="select" name="supervisor">
                  <option value="select">Active</option>
                  <option>Inactive</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mb-0 mr-3">
                <Label>Type</Label>
                <Input type="select" name="position">
                  <option value="select">Email</option>
                  <option>SMS </option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <div className="right">
            <Button color="primary" outline>
              <i className="fa fa-eraser"></i> Clear All
            </Button>
          </div>
        </div>

        <Table>
          <thead>
            <tr>
              <th>Template Name</th>
              <th>Category</th>
              <th>Type</th>
              <th>Sent Count</th>
              <th>Attachments</th>
              <th>Status</th>
              <th style={{ width: 140, textAlign: "right" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>New Design</td>
              <td>Change Order</td>
              <td>SMS</td>
              <td className="cursorPointer">
                <span className="link" onClick={() => _MesLogToggle(true)}>
                  20
                </span>
              </td>
              <td>
                <i className="fa fa-picture-o"></i> Screenshot 2024-05-08 at
                11.15.36 PM
              </td>
              <td>
                <CustomInput
                  type="switch"
                  id="exampleCustomSwitch"
                  name="customSwitch"
                />
              </td>
              <td>
                <div className="action">
                  <Button color="link" className="btn-edit">
                    <i className="fa fa-pencil"></i>
                  </Button>
                  <Button color="link" className="btn-delete">
                    <i className="fa fa-trash-o"></i>
                  </Button>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={7}>
                <div className="noData">
                  <img
                    src={require("../assets/img/noData.svg")}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* modal */}
      <NewTextTemplate isOpen={smsModal} toggle={() => _SmsToggle()} />
      <NewEmailTemplate isOpen={emailModal} toggle={() => _EmailToggle()} />
      <MessageLogs isOpen={messageLogsModal} toggle={() => _MesLogToggle()} />
    </>
  );
};

export default MessageTemplates;
