// import config from "../config";
// import { decodeToken, logout } from "../helper-methods/index";
import { store } from "../redux/store";

export const isUserAuthenticated = () => {
  const state = store.getState();
  let isUserAuth = false;

  // if (localStorage[config.TOKEN_NAME]) {
  //   const tokenData = decodeToken(localStorage[config.TOKEN_NAME]);

  //   const expDate = new Date(tokenData.exp * 1000); // expire in sec. convert in msec.

  //   if (expDate <= new Date()) {
  //     console.log("expire");
  //     logout();
  //   } else {
  //     isUserAuth = true;
  //   }
  // }
  if (state?.userCredential?.token) {
    isUserAuth = true;

  }
  return isUserAuth;
};
