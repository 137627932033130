import React from "react";
import { Link } from "react-router-dom";

import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const MessageLogs = (props) => {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        size="lg"
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={props.toggle}>Message Sent Log </ModalHeader>

        <ModalBody>
          <Table responsive className="text-center">
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Name</th>
                <th>Project</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>12 Dec 2021</td>
                <td>11:50 AM</td>
                <td>
                  <Link to="/dashboard/clientdetail/:1">John Doe</Link>
                </td>
                <td>
                  <Link to="/dashboard/projectdetail/:1">
                    John's House Painting
                  </Link>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <div className="noData">
                    <img
                      src={require("../../assets/img/noData.svg")}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MessageLogs;
