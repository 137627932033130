import { useEffect, useState } from "react";
import runOneSignal, { OneSignalHelper } from "../onesignal";

const useOneSignal = () => {
  const [userData, setUserData] = useState(() => {
    const data = JSON.parse(localStorage.getItem("users"));
    return data || [];
  });

  useEffect(() => {
    runOneSignal();

    const _initializeOneSignal = async () => {
      try {
        // check if notification permission is enabled
        const hasPermission = await OneSignalHelper.getNotificationPermission();

        if (hasPermission) {
          // get device id
          const deviceId = await OneSignalHelper.getDeviceId();
          if (deviceId) {
            const index = userData.findIndex(
              (user) => user.deviceId === deviceId
            );

            if (index === -1) {
              // add device id to local storage
              const newUserData = [...userData];
              newUserData.push({ deviceId });
              setUserData(newUserData);
              localStorage.setItem("users", JSON.stringify(newUserData));
            }
          }
        } else {
          OneSignalHelper.showPrompt();
          OneSignalHelper.onEnableSubscription(async () => {
            const deviceId = await OneSignalHelper.getDeviceId();
            console.log("deviceId", deviceId);
            if (deviceId) {
              // add device id to local
              const newUserData = [...userData];
              newUserData.push({ deviceId });
              setUserData(newUserData);
              localStorage.setItem("users", JSON.stringify(newUserData));
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    _initializeOneSignal();
  }, [userData]);
};

export default useOneSignal;