import React from "react";

const SvgIcons = ({ type, className }) => {
  switch (type) {
    case "phone": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="23"
            viewBox="0 0 24 23"
          >
            <defs>
              <clipPath id="clipPath">
                <rect
                  id="Rectangle_5"
                  data-name="Rectangle 5"
                  width="24"
                  height="23"
                  transform="translate(-1)"
                  fill="#16346c"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_2"
              data-name="Mask Group 2"
              transform="translate(1)"
              clipPath="url(#clipPath)"
            >
              <path
                id="phone-call_11_"
                data-name="phone-call (11)"
                d="M12.479.96a.96.96,0,0,1,.96-.96,9.61,9.61,0,0,1,9.6,9.6.96.96,0,1,1-1.92,0A7.688,7.688,0,0,0,13.439,1.92a.96.96,0,0,1-.96-.96Zm.96,4.8a3.84,3.84,0,0,1,3.84,3.84.96.96,0,1,0,1.92,0A5.765,5.765,0,0,0,13.439,3.84a.96.96,0,1,0,0,1.92Zm8.728,10.308a2.976,2.976,0,0,1,0,4.2l-.874,1.007C13.432,28.8-5.7,9.678,1.711,1.791l1.1-.96A2.957,2.957,0,0,1,6.969.87C7,.9,8.777,3.21,8.777,3.21a2.976,2.976,0,0,1-.007,4.11l-1.112,1.4a12.268,12.268,0,0,0,6.653,6.666l1.406-1.118a2.976,2.976,0,0,1,4.109-.006S22.137,16.038,22.167,16.068Zm-1.321,1.4s-2.3-1.767-2.327-1.8a1.056,1.056,0,0,0-1.487,0c-.026.027-1.962,1.569-1.962,1.569a.96.96,0,0,1-.94.146A14.407,14.407,0,0,1,5.663,8.927a.96.96,0,0,1,.139-.96S7.345,6.03,7.371,6.005a1.056,1.056,0,0,0,0-1.487c-.03-.029-1.8-2.328-1.8-2.328a1.056,1.056,0,0,0-1.449.037l-1.1.96C-2.395,9.7,14.183,25.359,19.89,19.966l.874-1.008a1.076,1.076,0,0,0,.082-1.495Z"
                transform="translate(-0.465 0.002)"
                fill="#16346c"
              />
            </g>
          </svg>
        </>
      );
    }
    case "email": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="23"
            viewBox="0 0 24 23"
          >
            <defs>
              <clipPath id="clipPath">
                <rect
                  id="Rectangle_6"
                  data-name="Rectangle 6"
                  width="24"
                  height="23"
                  transform="translate(-1)"
                  fill="#16346c"
                />
              </clipPath>
            </defs>
            <g
              id="Mask_Group_3"
              data-name="Mask Group 3"
              transform="translate(1)"
              clipPath="url(#clipPath)"
            >
              <path
                id="at_3_"
                data-name="at (3)"
                d="M11.5,0A11.509,11.509,0,0,0,0,11.5C-.121,20.668,10.691,26.278,18.107,20.9A.958.958,0,1,0,17,19.336c-6.177,4.477-15.183-.2-15.087-7.84.526-12.709,18.637-12.707,19.161,0v1.916a1.916,1.916,0,0,1-3.832,0V11.5C17,3.9,5.989,3.9,5.748,11.5a5.765,5.765,0,0,0,10.079,3.768,3.832,3.832,0,0,0,7.166-1.852V11.5A11.509,11.509,0,0,0,11.5,0Zm0,15.329A3.832,3.832,0,1,1,15.329,11.5,3.832,3.832,0,0,1,11.5,15.329Z"
                transform="translate(-0.423)"
                fill="#16346c"
              />
            </g>
          </svg>
        </>
      );
    }
    case "tiktok": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-tiktok"
            viewBox="0 0 16 16"
          >
            <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
          </svg>
        </>
      );
    }
    case "facebook": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-facebook"
          >
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
          </svg>
        </>
      );
    }
    case "linkdin": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-linkedin"
          >
            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
            <rect width="4" height="12" x="2" y="9" />
            <circle cx="4" cy="4" r="2" />
          </svg>
        </>
      );
    }
    case "instagram": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-instagram"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
            <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
          </svg>
        </>
      );
    }
    case "backArrow": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-chevron-left"
          >
            <path d="m15 18-6-6 6-6" />
          </svg>
        </>
      );
    }
    case "check": {
      return (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-check"
          >
            <path d="M20 6 9 17l-5-5" />
          </svg>
        </>
      );
    }
    case "filter": {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-sliders-horizontal"><line x1="21" x2="14" y1="4" y2="4" /><line x1="10" x2="3" y1="4" y2="4" /><line x1="21" x2="12" y1="12" y2="12" /><line x1="8" x2="3" y1="12" y2="12" /><line x1="21" x2="16" y1="20" y2="20" /><line x1="12" x2="3" y1="20" y2="20" /><line x1="14" x2="14" y1="2" y2="6" /><line x1="8" x2="8" y1="10" y2="14" /><line x1="16" x2="16" y1="18" y2="22" /></svg>
        </>
      )
    }
    case "docFile": {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="lucide lucide-file"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /></svg>
        </>
      )
    }

    default: {
      return <></>;
    }
  }
};

export default SvgIcons;
