/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import {
  addNote,
  deleteNote,
  editNote,
  fetchNotes,
} from "../../http/http-calls";
import { showToast } from "../../helper-methods";
import moment from "moment";

const ProviderNotesModal = ({ isOpen, toggle, id, fetchAllProvider }) => {
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState({
    note: "",
  });

  const [isDirty, setIsDirty] = useState({
    note: false,
  });
  const [notesId, setNotesId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const _closeModal = () => {
    toggle();
  };

  const _fetchAllNotes = async () => {
    setLoading(true);
    try {
      const res = await fetchNotes({ providerId: id });
      console.log("res>>", res);
      setNotes(res?.data);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  //onChange
  const _handelOnChange = (field, event) => {
    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };

    newFormData[field] = event.target.value;

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "note" && dirties[each]) {
        if (!data.note.trim()) {
          newErrors.note = "*Required";
        } else if (
          data.note.trim().length < 2 ||
          data.note.trim().length > 150
        ) {
          newErrors.note = "*Note allowed min 2 - max 150 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _toggleEditNotes = (note) => {
    if (note?._id) {
      setFormData({ ...formData, note: note.note });
      setNotesId(note._id);
    } else {
      setFormData({ ...formData, note: "" });
      setNotesId(null);
    }
  };

  const _submitNotesHandler = async () => {
    const newIsDirty = {
      note: true,
    };
    const error = _validateForm(formData, newIsDirty);
    console.log("errors => ", error);
    if (!error) {
      setLoading(true);
      if (notesId) {
        let payload = { ...formData, providerId: id };
        try {
          const res = await editNote(notesId, payload);
          console.log("res", res);
          setFormData({ ...formData, note: "" });
          setNotesId(null);
          _fetchAllNotes();
          fetchAllProvider()
          showToast("Note created", "success");
        } catch (error) {
          console.log("error", error);
          showToast(error?.reason, "error");
        }
      } else {
        let payload = { ...formData, providerId: id };
        try {
          const res = await addNote(payload);
          console.log("res", res);
          setFormData({ ...formData, note: "" });
          _fetchAllNotes();
          fetchAllProvider()
          showToast("Note created", "success");
        } catch (error) {
          console.log("error", error);
          showToast(error?.reason, "error");
        }
      }
      setLoading(false);
    }
  };

  const _deleteLeadNotesAlert = async (data) => {
    setDeleteLoading(true);
    try {
      const res = await deleteNote(data?._id, { providerId: id });
      _fetchAllNotes();
      fetchAllProvider()
      showToast(res?.message, "success");
    } catch (error) {
      showToast(error?.reason, "error");
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    _fetchAllNotes();
  }, [id,]);




  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={_closeModal}
        centered
      >
        <ModalHeader>Notes</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="textarea"
              placeholder="Enter"
              rows={6}
              value={formData?.note}
              onChange={(event) => _handelOnChange("note", event)}
              onBlur={() => _onBlurFormFields("note")}
            />
            {errors.note ? (
              <div className="form-error">{errors.note}</div>
            ) : null}
          </FormGroup>
          <div className="text-right">
            <Button
              className="h-auto"
              color="primary"
              outline
              onClick={() => _submitNotesHandler()}
            >
              {notesId ? "Update" : "Add"}
            </Button>
          </div>
          <ul className="notes-list">
            {notes?.length ? (
              notes.map((each) => (
                <li key={each._id} className="notesItem">
                  <div className="notesInfo">
                    <h3>
                      {moment(each.createdAt).format("MMM Do YYYY")}
                    </h3>
                    <div className="action">
                      <Button
                        color="link"
                        size="sm"
                        onClick={() => _toggleEditNotes(each)}
                      >
                        <i className="fa fa-pencil text-primary" />
                      </Button>
                      <Button
                        color="link"
                        size="sm"
                        disabled={deleteLoading ? true : false}
                        onClick={() => _deleteLeadNotesAlert(each)}
                      >
                        {deleteLoading === "123" ? (
                          <i className="fa fa-spinner fa-spin text-danger" />
                        ) : (
                          <i className="fa fa-trash text-danger" />
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className="notes-Content" >
                    <p className="" style={{ whiteSpace: 'pre-line' }}>{(each.note)}</p>
                  </div>


                </li>
              ))
            ) : loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <div className="noData">
                <img
                  src={require("../../assets/img/noData.svg")}
                  alt="no data"
                />
                <p>There is no data to display</p>
              </div>
            )}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal >
    </>
  );
};

export default ProviderNotesModal;
