const county = [
    "Adams",
    "Allen",
    "Bartholomew",
    "Benton",
    "Blackford",
    "Boone",
    "Brown",
    "Carroll",
    "Cass",
    "Clark",
    "Clay",
    "Clinton",
    "Crawford",
    "Daviess",
    "Dearborn",
    "Decatur",
    "DeKalb",
    "Delaware",
    "Dubois",
    "Elkhart",
    "Fayette",
    "Floyd",
    "Fountain",
    "Franklin",
    "Fulton",
    "Gibson",
    "Grant",
    "Greene",
    "Hamilton",
    "Hancock",
    "Harrison",
    "Hendricks",
    "Henry",
    "Howard",
    "Huntington",
    "Jackson",
    "Jasper",
    "Jay",
    "Jefferson",
    "Jennings",
    "Johnson",
    "Knox",
    "Kosciusko",
    "LaGrange",
    "Lake",
    "LaPorte",
    "Lawrence",
    "Madison",
    "Marion",
    "Marshall",
    "Martin",
    "Miami",
    "Monroe",
    "Montgomery",
    "Morgan",
    "Newton",
    "Noble",
    "Ohio",
    "Orange",
    "Owen",
    "Parke",
    "Perry",
    "Pike",
    "Porter",
    "Posey",
    "Pulaski",
    "Putnam",
    "Randolph",
    "Ripley",
    "Rush",
    "St. Joseph",
    "Scott",
    "Shelby",
    "Spencer",
    "Starke",
    "Steuben",
    "Sullivan",
    "Switzerland",
    "Tippecanoe",
    "Tipton",
    "Union",
    "Vanderburgh",
    "Vermillion",
    "Vigo",
    "Wabash",
    "Warren",
    "Warrick",
    "Washington",
    "Wayne",
    "Wells",
    "White",
    "Whitley",
  ];
  export const countyList = county.map((each) => {
    return {
      label: each,
      value: each,
    };
  });