import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import "./App.scss";
import { createBrowserHistory } from "history";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import PublicRoute from "./components/public-route";
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout"
import ForgotPassword from "./pages/forgot-password"
import Login from "./pages/login"
import useOneSignal from "./hooks/useOneSignal";

export const history = createBrowserHistory();

const App = () => {
  useOneSignal()
    return (
      <BrowserRouter>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.BOTTOM_RIGHT}
        />

        {/* <Navbar /> */}
        <>
          <Switch>
            <PublicRoute
              path="/login"
              component={Login}
              redirectRoute={"/home"}
            />
            <PublicRoute
              path="/forgot-password"
              component={ForgotPassword}
              redirectRoute={"/home"}
            />

            <Route path="/" component={DefaultLayout} />

            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </>

      </BrowserRouter>
    );
  
}

export default App;
