import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  Button,
  CustomInput,
} from "reactstrap";
import AddServices from "../components/Modal/AddServices";
import { deepClone, showToast } from "../helper-methods";
import {
  deleteOneService,
  fetchCategories,
  fetchServices,
  serviceStatus,
} from "../http/http-calls";
import moment from "moment";
import DeleteAlertModal from "../components/Modal/DeleteAlertModal";
import PaginatedItems from "../components/PaginatedItems";
import { useSelector } from "react-redux";
import SvgIcons from "../components/SvgIcons";

const Services = () => {
  /* eslint-disable */
  const userData = useSelector((state) => state?.userCredential?.user);

  const [addServices, SetAddServices] = useState(false);
  const [services, setServices] = useState([]);
  const [editDetails, setEditDetails] = useState(null);
  const [delModeOpen, setDelModeOpen] = useState(false);
  const [deleteId, setDelId] = useState("");
  const [payload, setPayload] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const [category, setCategory] = useState([]);
  const [count, setCount] = useState("");
  const [itemsPerPages, setItemsPerPages] = useState("");
  const [flag, setFlag] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const _servicesToggle = (isOpen = false) => {
    SetAddServices(isOpen);
    setEditDetails(null);
  };

  const _fetchAllServices = async (newPayload) => {
    try {
      const res = await fetchServices(newPayload ? newPayload : payload);
      console.log("allservices", res);
      setServices(res?.data);
      setCount(res?.serviceCount);
      setItemsPerPages(res?.data?.length);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  const _fetchAllCategory = async () => {
    try {
      const res = await fetchCategories();
      console.log("allCategory", res);
      setCategory(res?.arr);
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  useEffect(() => {
    _fetchAllServices();
    // _fetchAllCategory();
  }, []);

  //edit

  const _editHandler = (details) => {
    _servicesToggle(true);
    setEditDetails(details);
  };

  //status change
  const _changeStatus = async (event, id, index) => {
    console.log("id", id);
    setFlag(true);
    let oldReports = JSON.parse(JSON.stringify(services));

    const value = event.target.checked;
    services[index].isActive = value;
    setServices([...services]);

    try {
      const res = await serviceStatus(id, { status: value });
      console.log("resForUpdatingStatus>>", res);
      showToast("Service Updated Successfully!", "success");
      // _fetchAllServices();
    } catch (error) {
      setServices(oldReports);
      console.log(error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
    setFlag(false);
  };

  //filter and search
  const _statusHandler = async (field, event) => {
    const newSearchData = payload;
    newSearchData[field] = event.target.value;

    if (event.target.value?.length) {
      setPayload[field] = event.target.value;
      setPayload({ ...payload, pageNumber: 1 });
    } else {
      delete newSearchData[field];
      _fetchAllServices();
    }

    // _checkHandler(newSearchData);
  };

  //debouncing

  useEffect(() => {
    if (
      payload.hasOwnProperty("searchText") ||
      payload.hasOwnProperty("serviceType") ||
      payload.hasOwnProperty("numberCount") ||
      payload.hasOwnProperty("isActive")
    ) {
      const _checkHandler = setTimeout(async () => {
        try {
          const res = await fetchServices(payload);
          console.log("searchFilter>>", res);
          setServices(res?.data);
          setCount(res?.serviceCount);
          setItemsPerPages(res?.data?.length);
        } catch (error) {
          console.log(error);
          showToast(
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime.",
            "error"
          );
        }
      }, 500);
      return () => clearTimeout(_checkHandler);
    }
  }, [payload]);

  //delete

  const _deleteToggle = (delModeOpen = false, id) => {
    setDelModeOpen(delModeOpen);
    setDelId(id);
  };

  const _removeUser = async (memberId) => {
    // console.log(memberId);
    _deleteToggle(true, memberId);
  };

  //delete user handler
  const _deleteHandler = async () => {
    try {
      const res = await deleteOneService(deleteId);
      console.log("deleteUser", res);
      _fetchAllServices();
      _fetchAllCategory();
      showToast(
        res.message && res.message.length
          ? res.message
          : "User Deleted Successfully!",
        "success"
      );
    } catch (error) {
      console.log("error", error);
      showToast(
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
    }
  };

  const _clearAllHandler = () => { 
    document.getElementById("searchText").value = "";
    // document.getElementById("serviceType").value = "";
    document.getElementById("numberCount").value = "";
    document.getElementById("status").value = "";
    setPayload({
      pageNumber: 1,
      pageSize: 10,
    });
    _fetchAllServices({
      pageNumber: 1,
      pageSize: 10,
    });
  };

  //pagination
  const _handlePageClick = (pageNumber) => {
    // console.log(pageNumber);
    let newPageConfig = deepClone(payload);
    newPageConfig["pageNumber"] = pageNumber + 1;
    setPayload(newPageConfig);
    _fetchAllServices(newPageConfig);
  };

  return (
    <>
      <div className="InnerWrapper">
        <div className="PgTitle">
          <h2 className="pg-heading">Resources</h2>
          <div className="right">
            {userData?.resource?.add ? (
              <Button
                color="primary"
                onClick={() => {
                  _servicesToggle(true);
                }}
              >
                <i className="fa fa-plus mr-2"></i>
                Add Resource
              </Button>
            ) : null}

            <Button
              color="link"
              className="filterIcon"
              onClick={() => setShowFilter(!showFilter)}
            >
              <SvgIcons type={"filter"} />
            </Button>
          </div>
        </div>

        {/* filters */}
        <div
          className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}
        >
          <h3>Filters</h3>

          <div className="filterWarp">
            <FormGroup className="searchBox">
              <Label>Search</Label>
              <InputGroup>
                <Input
                  data-test="search-input-filter"
                  type="text"
                  name="search"
                  placeholder="Search"
                  autoComplete="off"
                  id="searchText"
                  onChange={(event) => _statusHandler("searchText", event)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText data-test="search-button">
                    <i className="icon-magnifier"></i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {/* <FormGroup>
              <Label>Category</Label>
              <Input
                type="select"
                name="supervisor"
                id="serviceType"
                onChange={(event) => _statusHandler("serviceType", event)}
              >
                <option value="">All</option>
                {category?.map((each, index) => (
                  <option key={index} value={each}>
                    {each}
                  </option>
                ))}
              </Input>
            </FormGroup> */}
            <FormGroup>
              <Label>Searches</Label>
              <Input
                type="select"
                name="branch"
                id="numberCount"
                onChange={(event) => _statusHandler("numberCount", event)}
              >
                <option value="">All</option>
                <option value="1-10">1 - 10</option>
                <option value="11-50">11 - 50</option>
                <option value="51-100">51 - 100</option>
                <option value="101-1000">101 - 1000</option>
                <option value="1000"> 1000 +</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <Input
                type="select"
                name="branch"
                id="status"
                onChange={(event) => _statusHandler("isActive", event)}
              >
                <option value="">All</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </Input>
            </FormGroup>
            <Button
              color="danger"
              className="reset h-auto"
              outline
              onClick={() => _clearAllHandler()}
            >
              <i className="fa fa-eraser"></i>
            </Button>
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Resource</th>
              {/* <th>Category</th> */}
              <th>
                <div className="clicks">
                  <Link to="#">
                    <i className="fa fa-phone"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-envelope-o"></i>
                  </Link>
                  <Link to="#">
                    <i className="fa fa-link"></i>
                  </Link>
                </div>
              </th>
              <th>Searches</th>
              <th>Added On</th>
              <th>Status</th>
              {userData?.resource?.edit ? (
                <th style={{ width: 140, textAlign: "right" }}>Actions</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {services?.length ? (
              services?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.serviceName}</td>
                  {/* <td>{data?.serviceType}</td> */}
                  <td>
                    <div className="clicks">
                      <Link>{data?.phoneClickCount}</Link>
                      <Link>{data?.mailClickCount}</Link>
                      <Link>{data?.urlClickCount}</Link>
                    </div>
                  </td>
                  <td>{data?.numberOfVetSearchByService}</td>
                  <td>{moment(data?.registrationDate).format("MMM YYYY")}</td>
                  <td>
                    <CustomInput
                      type="switch"
                      id={data._id}
                      name="customSwitch"
                      checked={data?.isActive}
                      disabled={flag || !userData?.resource?.edit}
                      onChange={(event) =>
                        _changeStatus(event, data?._id, index)
                      }
                    />
                  </td>
                  {userData?.resource?.edit ? (
                    <td>
                      <div className="action">
                        <Button
                          color="link"
                          className="btn-edit"
                          onClick={() => _editHandler(data)}
                        >
                          <i className="fa fa-pencil"></i>
                        </Button>
                        <Button
                          color="link"
                          className="btn-delete"
                          onClick={() => _removeUser(data?._id)}
                        >
                          <i className="fa fa-trash-o"></i>
                        </Button>
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="noData">
                    <img
                      src={require("../assets/img/noData.svg")}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <PaginatedItems
          itemsPerPages={itemsPerPages}
          totalCount={count}
          selectedPage={payload.pageNumber}
          itemsPerPage={payload.pageSize}
          onPageChange={_handlePageClick}
        />
      </div>
      <AddServices
        isOpen={addServices}
        toggle={() => _servicesToggle()}
        isEdit={editDetails === null ? false : true}
        editDetails={editDetails}
        fetchAllServices={_fetchAllServices}
        fetchAllCategory={_fetchAllCategory}
      />
      <DeleteAlertModal
        delModeOpen={delModeOpen}
        deleteService={() => _deleteHandler()}
        toggle={() => _deleteToggle()}
      />
    </>
  );
};

export default Services;
